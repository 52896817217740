import * as Yup from "yup";
import { phoneRegex, passwordRegex } from "../shared/global-constants";

//AUTH MODULE SCHEMA
export const AuthSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),

  password: Yup.string().required("Required"),
});

//LEAVE MODULE FORMS SCHEMA
export const LeaveCreateSchema = Yup.object({
  type: Yup.string().required("Please enter type of leave"),

  reason: Yup.string().required("Please enter reason for leave"),

  fromdate: Yup.date().required("Required"),

  todate: Yup.date()
    .min(Yup.ref("fromdate"), "It should be a date after fromdate")
    .required("Required"),
});

export const LeaveUpdateSchema = Yup.object({
  type: Yup.string().required("Please enter type of leave"),

  reason: Yup.string().required("Please enter reason for leave"),

  fromdate: Yup.date().required("Required"),

  todate: Yup.date()
    .min(Yup.ref("fromdate"), "It should be a date after fromdate")
    .required("Required"),

  status: Yup.string().required("Please select status of leave"),

  rejectreason: Yup.string().notRequired(),
});

export const LeaveStatusSchema = Yup.object({
  rejectreason: Yup.string().required("Required"),
});

export const ReceiptGenerateSchema = Yup.object({
  name: Yup.string().required("Required").nullable(),
});

//MEMBERS MODULE FORM SCHEMA
export const MemberCreateSchema = Yup.object({
  username: Yup.string().required("Please enter Username"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Please enter email"),

  role: Yup.string().required("Please mention role"),

  mobileno: Yup.string()
    .matches(phoneRegex, "Phone number is not valid")
    .required("Please enter mobile number"),

  password: Yup.string()
    .matches(passwordRegex, " ")
    .required("Please enter a password"),

  designation: Yup.string().required("Please enter Designation"),
  empid: Yup.string().required("Please enter Employee Id"),
  name: Yup.string().required("Please enter Name"),
  doj: Yup.date().required("Please enter Date of Joining"),
  probationenddate: Yup.date().notRequired(),
  internshipenddate: Yup.date().notRequired(),
  dob: Yup.string().required("Please enter Date of Birth"),
});

export const MemberUpdateSchema = Yup.object({
  username: Yup.string().required("Please enter Username"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Please enter email"),

  role: Yup.string().required("Please mention role"),

  mobileno: Yup.string()
    .matches(phoneRegex, "Phone number is not valid")
    .required("Please enter mobile number"),
  designation: Yup.string().required("Please enter Designation"),
  empid: Yup.string().required("Please enter Employee Id"),
  doj: Yup.date().required("Please enter Date of Joining"),
  dob: Yup.string().required("Please enter Date of Birth"),
});

//FINANCE MODULE FORM SCHEMA
export const FinanceSchema = Yup.object({
  type: Yup.string().required("Mention a type"),

  amount: Yup.number().required("Please enter valid number"),

  status: Yup.string().required(),

  reason: Yup.string().required("Please mention a reason"),

  transactionid: Yup.string().notRequired(),
});

export const FinanceReportSchema = Yup.object({
  reason: Yup.string().required("Enter a reason"),
});

//PROJECT MODULE FORM SCHEMA
export const ProjectSchema = Yup.object({
  name: Yup.string().required("Name required"),

  description: Yup.string().required("Enter description"),

  startDate: Yup.date().required("Enter start date"),

  endDate: Yup.date()
    .min(Yup.ref("startDate"), "It should be a date after startdate")
    .required("Enter end date"),

  client: Yup.string()
    .required("Enter Client info")
    .typeError("Must be a string"),

  technologies: Yup.array()
    .min(1, "Enter atleast one and press ENTER")
    .max(50)
    .required("Required"),
});

//SKILLSCORE SCHEMA
export const SkillscoreSchema = Yup.object({
  name: Yup.string().required("Required"),
  aspiringscore: Yup.number().min(1).max(5).required(),
  currentscore: Yup.number().min(1).max(5).required(),
});

//CLIENT SCHEMA
export const ClientSchema = Yup.object({
  name: Yup.string().required("Required"),

  company: Yup.string().required("Required"),

  address: Yup.string().required("Required"),

  phonenumber: Yup.string()
    .matches(phoneRegex, "Phone number is not valid")
    .required("Please enter mobile number"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Please enter email"),
});

//FAQ SCHEMA
export const faqQuesSchema = Yup.object({
  question: Yup.string().required("Required"),
});

export const faqAnsSchema = Yup.object({
  answer: Yup.string().required("Required"),

  answerText: Yup.string().required("Required"),
});

//KNOWLEDGE BASE SCHEMA
export const kbSchema = Yup.object().shape({
  name: Yup.string().required("Required"),

  description: Yup.string().required("Required"),

  version: Yup.string().required("Required"),
});

// Job Application Schema for Career Page
export const JobApplicationSchema = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  mobilenumber: Yup.string()
    .matches(phoneRegex, "Phone number is not valid")
    .required("Required"),
});
