export const mapUserWithMeta = (data) => {
  const {
    usermeta: {
      _id: metaid,
      designation,
      dob,
      doj,
      empid,
      name,
      experience,
      probation,
      probationenddate,
      internship,
      internshipenddate,
    },
    ...user
  } = data;
  return {
    metaid,
    designation,
    dob,
    doj,
    empid,
    name,
    experience,
    probation,
    probationenddate,
    internship,
    internshipenddate,
    ...user,
  };
};

export const calculateDaysBtwTwoDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diff = end.getTime() - start.getTime();
  const days = diff / 1000 / 60 / 60 / 24;
  return Math.round(days);
};

export const formatDate = (inputDate) => {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, "0");

  month = month.toString().padStart(2, "0");

  return `${date}/${month}/${year}`;
};

export const toCapitalCase = (str) => {
  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str2 = arr.join(" ");
  return str2;
};
