// material-ui imports
import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import ListItemButton from '@mui/material/ListItemButton';
import GroupsIcon from '@mui/icons-material/Groups';
import { Typography } from '@mui/material';

//react router imports
import { NavLink } from "react-router-dom";

//component routes imports
import { sidePanelRoute, UserPermissions } from "../shared/global-constants";

const item = {
  color: "rgba(255, 255, 255, 0.7)",
  py: '2px',
  px: 3,
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

const subMenuHeading = {
  color: "#fff",
  opacity: "0.7",
  px: 2,
  py: 3
};

const subMenuItem = {
  opacity: "0.7",
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  }
};

const getColor = enabled => {
  return {
    color: enabled ? "rgba(255, 255, 255, 0.7)" : "rgba(68, 66, 66, 1)"
  };
};

const SidePanel = (props) => {
  const { ...other } = props;

  return (
    <React.Fragment>
      <Drawer variant="permanent" {...other}>
        <List disablePadding>

          <Typography sx={{ ...itemCategory, fontSize: 22, color: '#fff' }}>
            BudhanaTech LLP
          </Typography>

          <NavLink to={sidePanelRoute.dashboardRoute} style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem sx={{ ...item, ...itemCategory }}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>DASHBOARD</ListItemText>
            </ListItem>
          </NavLink>


          <ListItem sx={{ ...subMenuHeading }}>
            <ListItemText>TEAM</ListItemText>
          </ListItem>

          <List disablePadding>

            <NavLink to={sidePanelRoute.attendanceRoute} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <ListItem disablePadding sx={{ ...subMenuItem, ...getColor(UserPermissions.attendance.count) }}>
                <ListItemButton>
                  <ListItemIcon><PeopleIcon /></ListItemIcon>
                  <ListItemText>Attendance</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            <NavLink to={sidePanelRoute.leaveRoute} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <ListItem disablePadding sx={{ ...subMenuItem, ...getColor(UserPermissions.leave.count) }}>
                <ListItemButton>
                  <ListItemIcon><DnsRoundedIcon /></ListItemIcon>
                  <ListItemText>Leave</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            <NavLink to={sidePanelRoute.members} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <ListItem disablePadding sx={{ ...subMenuItem, ...getColor(UserPermissions.members.count) }}>
                <ListItemButton>
                  <ListItemIcon><GroupsIcon /></ListItemIcon>
                  <ListItemText>Members</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

          </List>

          <ListItem sx={{ ...subMenuHeading }}>
            <ListItemText>OTHERS</ListItemText>
          </ListItem>

          <NavLink to={sidePanelRoute.kb} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <ListItem disablePadding sx={{ ...subMenuItem, ...getColor(UserPermissions.kb.count) }}>
                <ListItemButton>
                  <ListItemIcon><GroupsIcon /></ListItemIcon>
                  <ListItemText>Knowledge Base</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

          <List disablePadding>
            <NavLink to={sidePanelRoute.client} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <ListItem disablePadding sx={{ ...subMenuItem, ...getColor(UserPermissions.client.count) }}>
                <ListItemButton>
                  <ListItemIcon><PermMediaOutlinedIcon /></ListItemIcon>
                  <ListItemText>Clients</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
            
            <NavLink to={sidePanelRoute.allProjects} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <ListItem disablePadding sx={{ ...subMenuItem, ...getColor(UserPermissions.projects.count) }}>
                <ListItemButton>
                  <ListItemIcon><PermMediaOutlinedIcon /></ListItemIcon>
                  <ListItemText>Projects</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            <NavLink to={sidePanelRoute.skills} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <ListItem disablePadding sx={{ ...subMenuItem, ...getColor(UserPermissions.skillstore.count) }}>
                <ListItemButton>
                  <ListItemIcon><PermMediaOutlinedIcon /></ListItemIcon>
                  <ListItemText>Skillstore</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            <NavLink to={sidePanelRoute.finances} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <ListItem disablePadding sx={{ ...subMenuItem, ...getColor(UserPermissions.finances.count) }}>
                <ListItemButton>
                  <ListItemIcon><PermMediaOutlinedIcon /></ListItemIcon>
                  <ListItemText>Finances</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            <NavLink to={sidePanelRoute.faq} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <ListItem disablePadding sx={{ ...subMenuItem, ...getColor(UserPermissions.faq.count) }}>
                <ListItemButton>
                  <ListItemIcon><PermMediaOutlinedIcon /></ListItemIcon>
                  <ListItemText>FAQ</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

          </List>
        </List>
      </Drawer>

    </React.Fragment>
  );
};

export default SidePanel;