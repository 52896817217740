import React from "react";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  {
    field: "username",
    headerName: "Name",
    width: 250,
    renderCell: (params) => {
      return (
        <div className="rowitem">
          {params.row.users_permissions_user.username}
        </div>
      );
    },
  },
  {
    field: "date",
    headerName: "Date",
    width: 250,
  },
  {
    field: "in_time",
    headerName: "In Time",
    width: 150,
  },
  {
    field: "out_time",
    headerName: "Out Time",
    width: 150,
  },
  {
    field: "workinghrs",
    headerName: "Working Hours",
    width: 150,
  },
];

const AttendanceTable = (props) => {
  return (
    <>
      <Card>
        <div style={{ height: 530, width: "100%" }}>
          <DataGrid
            rows={props.data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            sx={{
              "& .MuiDataGrid-cell:hover": {
                cursor: "pointer",
              },
            }}
          />
        </div>
      </Card>
    </>
  );
};
export default AttendanceTable;
