import * as React from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DisplaySnackbar from '../../../../shared/Components/DisplaySnackbar';
import { useFormik } from "formik";
import { finances } from "../../../../shared/HTTP/HttpCalls";
import { financeStatus, reimbursementsType } from "../../../../shared/global-constants";
import { snackbarData, snackbarStart } from '../../../../shared/Components/DisplaySnackbar';
import { FinanceSchema } from '../../../../shared/FormValidationSchema';
import { Upload } from "../../../../shared/HTTP/HttpCalls";

const FinanceCreateModal = (props) => {

  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);
  const [file, setFile] = React.useState([]);

  const onSubmit = async (formData) => {
    try {
      if (file.length !== 0) {
        const fileUploaded = await Upload.create(file);
        formData["filesrc"] = fileUploaded.data[0]._id;
      }
      const financeUpload = await finances.create(formData);
      props.tableUpdater([financeUpload.data, ...props.rows]);
      setSnackbarOpen(snackbarStart("success", "Record Added", financeUpload.statusText, financeUpload.status));
    } catch (err) {
      setSnackbarOpen(snackbarStart("error", err.toString()));
      console.log(err);
    }
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      type: "",
      amount: "",
      status: "",
      reason: "",
      transactionid: "",
    },
    onSubmit,
    validationSchema: FinanceSchema
  });

  const handleFiles = (event) => {
    let fData = new FormData();
    fData.append(event.target.name, event.target.files[0]);
    setFile(fData);
  };

  const handleClose = () => {
    props.dialogClose();
    formik.resetForm();
    setFile([]);
  };

  return (
    <>
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          New Reimbursement
          <div id="icons" style={{ display: "flex", alignItems: "center" }}>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...formik.getFieldProps("type")}
                {... (formik.touched.type && formik.errors.type && { error: true })}
                helperText={(formik.touched.type && formik.errors.type) || " "}
                label="Type"
                variant="filled"
                defaultValue="Others"
                select
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              >
                {reimbursementsType.map(status =>
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                )}
              </TextField>
              <TextField
                {...formik.getFieldProps("amount")}
                {... (formik.touched.amount && formik.errors.amount && { error: true })}
                helperText={(formik.touched.amount && formik.errors.amount) || " "}
                label="Amount"
                type="number"
                variant="filled"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              ></TextField>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...formik.getFieldProps("transactionid")}
                {... (formik.touched.transactionid && formik.errors.transactionid && { error: true })}
                helperText={(formik.touched.transactionid && formik.errors.transactionid) || " "}
                label="Transaction Id"
                variant="filled"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              >
              </TextField>

              <TextField
                {...formik.getFieldProps("status")}
                {... (formik.touched.status && formik.errors.status && { error: true })}
                helperText={(formik.touched.status && formik.errors.status) || " "}
                label="Status"
                defaultValue="pending"
                select
                variant="filled"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              >
                {financeStatus.map(status =>
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                )}
              </TextField>
            </Stack>

            <TextField
              {...formik.getFieldProps("reason")}
              {... (formik.touched.reason && formik.errors.reason && { error: true })}
              helperText={(formik.touched.reason && formik.errors.reason) || " "}
              label="Reason"
              minRows={3}
              multiline
              variant="filled"
              sx={{ width: "100%", padding: "5px" }}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            component="label"
            sx={{ margin: "0px 2px" }}
          >
            {file.length !== 0 ? "ATTACHED" : "UPLOAD FILE"}
            <input
              id="files" name="files"
              type="file"
              onChange={handleFiles}
              hidden
            />
          </Button>
          <Button
            disabled={!(formik.isValid && formik.dirty)}
            type="submit"
            onClick={formik.handleSubmit}
            variant="contained"
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>

      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbarOpen.statusCode}
      />

    </>
  );
};
export default FinanceCreateModal;