import * as React from 'react';
import { useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { auth, permission } from "../../shared/HTTP/HttpCalls";
import { STRINGS, LocalStorageKeys, sidePanelRoute } from "../../shared/global-constants";
import { AuthComponentRoutes } from "../../shared/global-constants";
import { setUser } from "../../shared/LocalStorage/LocalStorageCalls";
import { useFormik } from "formik";
import { AuthSchema } from "../../shared/FormValidationSchema";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={`${STRINGS.companyURL}`}>
                {STRINGS.companyName}
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
};

const theme = createTheme();

const Auth = () => {

    const [error, setError] = useState("");
    const history = useHistory()

    const onSubmit = () => {
        auth.newLogin({
            identifier: formik.values.email,
            password: formik.values.password
        }).then((res) => {
            try {
                permission.findOne(res.data.user.role._id)
                    .then(response => {
                        setUser({
                            [LocalStorageKeys.token]: res.data.jwt,
                            [LocalStorageKeys.username]: res.data.user.username,
                            [LocalStorageKeys.email]: res.data.user.email,
                            [LocalStorageKeys.userid]: res.data.user._id,
                            [LocalStorageKeys.roleid]: res.data.user.role._id,
                            [LocalStorageKeys.permision]: JSON.stringify(response.data),

                        });
                        history.go(sidePanelRoute.dashboardRoute);
                    })
                    .catch(err => console.log("Error :", err))
            }
            catch (error) {
                console.log("Error", error.message)
            }
        }).catch((err) => {
            console.log(err);
            setError("Username/Password doesn't match database");
        });
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        onSubmit,
        validationSchema: AuthSchema
    });

    return (
        <>
            <Route path="/">
                <Redirect to={`${AuthComponentRoutes.homeRoute}`}></Redirect>
            </Route>

            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>

                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>

                        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                {...formik.getFieldProps("email")}
                                {...(formik.errors.email && formik.touched.email && { error: true })}
                                helperText={(formik.touched.email && formik.errors.email) || " "}
                                type="email"
                                margin="normal"
                                fullWidth
                                label="Enter Email Address"
                                autoComplete="email"
                                autoFocus
                            />

                            <TextField
                                {...formik.getFieldProps("password")}
                                {...(formik.errors.password && formik.touched.password && { error: true })}
                                helperText={(formik.touched.password && formik.errors.password) || " "}
                                margin="normal"
                                fullWidth
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                            />

                            <Button
                                disabled={!(formik.dirty && formik.isValid)}
                                onClick={formik.handleSubmit}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                        </Box>
                        <Typography align="left" variant="caption" style={{ color: "red" }}>
                            {error}
                        </Typography>
                    </Box>
                    <br /><br />
                    <Copyright />
                </Container>
            </ThemeProvider>
        </>
    );
};
export default Auth;