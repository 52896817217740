import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";

const LeaveCard = (props) => {
  return (
    <Stack
      sx={{ mb: 2 }}
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 2, sm: 2, md: "1%" }}
    >
      <Card sx={{ maxWidth: "100%", minWidth: "32.66%" }}>
        <CardContent>
          <h4>Sick Leaves: {props.remainingLeaves.sickleaves} </h4>
        </CardContent>
      </Card>

      <Card sx={{ maxWidth: "100%", minWidth: "32.66%" }}>
        <CardContent>
          <h4>Paid Leaves: {props.remainingLeaves.paidleaves} </h4>
        </CardContent>
      </Card>

      <Card sx={{ maxWidth: "100%", minWidth: "32.66%" }}>
        <CardContent>
          <h4>Maternal Leaves: {props.remainingLeaves.maternalleaves} </h4>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default LeaveCard;
