import LocalStorageService from "./LocalStorageService";
import { LocalStorageKeys } from "../global-constants";

const storage = new LocalStorageService();

//getting localStorage keys
export const getToken = () => {
    return storage.get(LocalStorageKeys.token);
};

export const getEmail = () => {
    return storage.get(LocalStorageKeys.email);
};

export const getUserId = () => {
    return storage.get(LocalStorageKeys.userid);
};

export const getRoleId = () => {
    return storage.get(LocalStorageKeys.roleid);
};

export const getUsername = () => {
    return storage.get(LocalStorageKeys.username);
};

export const getPermission = () => {
    return storage.get(LocalStorageKeys.permision);
};

export const getRole = () => {
    return storage.get(LocalStorageKeys.role);
};

export const getSignedIn = () => {
    return storage.get(LocalStorageKeys.signedin);
};

//other calls from/to localStorage
export const setUser = (localData) => {
    for(let i in localData) {
        storage.set(i, localData[i]);
    }
};

export const logout = () => {
    storage.clearStorage();
};