import * as React from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DisplaySnackbar from "../../../../shared/Components/DisplaySnackbar";
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import { users } from "../../../../shared/HTTP/HttpCalls";

import {
  snackbarData,
  snackbarStart,
} from "../../../../shared/Components/DisplaySnackbar";
import { MemberCreateSchema } from "../../../../shared/FormValidationSchema";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { mapUserWithMeta } from "../../../../shared/utils";

const UserCreateModal = (props) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);

  const onSubmit = (formData) => {
    // delete undefined or empty fields from formData
    Object.keys(formData).forEach(
      (key) =>
        formData[key] === undefined ||
        (formData[key] === "" && delete formData[key])
    );

    users
      .create(formData)
      .then((res) => {
        res.data = mapUserWithMeta(res.data);
        props.tableUpdater([res.data, ...props.rows]);
        setSnackbarOpen(
          snackbarStart("success", "Record Added", res.statusText, res.status)
        );
      })
      .catch((err) => {
        setSnackbarOpen(snackbarStart("error", err.toString()));
      });
    handleClose();
  };

  const handleClose = () => {
    props.dialogClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      mobileno: "",
      role: "",
      password: "",
      confirmed: "true",
      rolesData: props.rolesData.roles,
      empid: "",
      designation: "",
      name: "",
      doj: "",
      experience: 0,
      probation: false,
      probationenddate: "",
      internship: false,
      internshipenddate: "",
      dob: "",
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: MemberCreateSchema,
  });

  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Create New User
          <div id="icons">
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </div>
        </DialogTitle>

        <DialogContent>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2 }}
          >
            <TextField
              {...formik.getFieldProps("name")}
              {...(formik.touched.name &&
                formik.errors.name && { error: true })}
              helperText={(formik.touched.name && formik.errors.name) || " "}
              sx={{ width: "100%", padding: "5px" }}
              label="Name"
              variant="filled"
              name="name"
              required
            />
            <TextField
              {...formik.getFieldProps("role")}
              {...(formik.touched.role &&
                formik.errors.role && { error: true })}
              helperText={(formik.touched.role && formik.errors.role) || " "}
              sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              label="Role"
              defaultValue=""
              select
              variant="filled"
              required
            >
              {formik.values.rolesData &&
                formik.values.rolesData.map((role) => (
                  <MenuItem key={role._id} value={role._id}>
                    {role.name}
                  </MenuItem>
                ))}
            </TextField>
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2 }}
          >
            <Tooltip
              arrow
              enterTouchDelay={0}
              title="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
            >
              <TextField
                {...formik.getFieldProps("password")}
                {...(formik.touched.password &&
                  formik.errors.password && { error: true })}
                helperText={
                  (formik.touched.password && formik.errors.password) || " "
                }
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
                type="text"
                label="Password"
                variant="filled"
                required
              ></TextField>
            </Tooltip>
            <TextField
              {...formik.getFieldProps("mobileno")}
              {...(formik.touched.mobileno &&
                formik.errors.mobileno && { error: true })}
              helperText={
                (formik.touched.mobileno && formik.errors.mobileno) || " "
              }
              sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              label="Mobile no."
              variant="filled"
              required
            ></TextField>
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2 }}
          >
            <TextField
              {...formik.getFieldProps("email")}
              {...(formik.touched.email &&
                formik.errors.email && { error: true })}
              helperText={(formik.touched.email && formik.errors.email) || " "}
              sx={{ width: "100%", padding: "5px" }}
              label="Email"
              variant="filled"
              required
            />
          </Stack>

          <Typography>Additional Info: </Typography>
          <hr />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2 }}
          >
            <TextField
              {...formik.getFieldProps("empid")}
              {...(formik.touched.empid &&
                formik.errors.empid && { error: true })}
              helperText={(formik.touched.empid && formik.errors.empid) || " "}
              sx={{ width: "100%", padding: "5px" }}
              label="Employee Id"
              variant="filled"
              required
            />
            <TextField
              {...formik.getFieldProps("username")}
              {...(formik.touched.username &&
                formik.errors.username && { error: true })}
              helperText={
                (formik.touched.username && formik.errors.username) || " "
              }
              sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              id="username"
              name="username"
              label="User Name"
              variant="filled"
              required
            ></TextField>
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2 }}
          >
            <TextField
              {...formik.getFieldProps("designation")}
              {...(formik.touched.designation &&
                formik.errors.designation && { error: true })}
              helperText={
                (formik.touched.designation && formik.errors.designation) || " "
              }
              sx={{ width: "100%", padding: "5px" }}
              id="designation"
              name="designation"
              label="Designation"
              variant="filled"
              required
            />
            <TextField
              {...formik.getFieldProps("doj")}
              {...(formik.touched.doj && formik.errors.doj && { error: true })}
              helperText={(formik.touched.doj && formik.errors.doj) || " "}
              sx={{ width: "100%", padding: "5px" }}
              type="date"
              label="Date of Joining"
              variant="filled"
              required
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2 }}
          >
            <TextField
              {...formik.getFieldProps("experience")}
              {...(formik.touched.experience &&
                formik.errors.experience && { error: true })}
              helperText={
                (formik.touched.experience && formik.errors.experience) || " "
              }
              sx={{ width: "100%", padding: "5px" }}
              label="Experience"
              variant="filled"
              type="number"
            />

            <FormControlLabel
              {...formik.getFieldProps("probation")}
              {...(formik.touched.probation &&
                formik.errors.probation && { error: true })}
              control={<Checkbox checked={formik.values.probation} />}
              label="Probation"
              name="probation"
            />
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2 }}
          >
            <TextField
              {...formik.getFieldProps("probationenddate")}
              {...(formik.touched.probationenddate &&
                formik.errors.probationenddate && { error: true })}
              helperText={
                (formik.touched.probationenddate &&
                  formik.errors.probationenddate) ||
                " "
              }
              sx={{ width: "100%", padding: "5px" }}
              label="Probation End Date"
              variant="filled"
              type="date"
            />

            <FormControlLabel
              {...formik.getFieldProps("internship")}
              {...(formik.touched.internship &&
                formik.errors.internship && { error: true })}
              control={<Checkbox checked={formik.values.internship} />}
              label="Internship"
              name="internship"
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2 }}
          >
            <TextField
              {...formik.getFieldProps("internshipenddate")}
              {...(formik.touched.internshipenddate &&
                formik.errors.internshipenddate && { error: true })}
              helperText={
                (formik.touched.internshipenddate &&
                  formik.errors.internshipenddate) ||
                " "
              }
              sx={{ width: "100%", padding: "5px" }}
              label="Internship End Date"
              variant="filled"
              type="date"
            />
            <TextField
              {...formik.getFieldProps("dob")}
              {...(formik.touched.dob && formik.errors.dob && { error: true })}
              helperText={(formik.touched.dob && formik.errors.dob) || " "}
              sx={{ width: "100%", padding: "5px" }}
              label="Date of Birth"
              variant="filled"
              type="date"
              required
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            disabled={!(formik.dirty && formik.isValid)}
            variant="contained"
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>

      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => {
          setSnackbarOpen({ open: false, severity: "", message: "" });
        }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbarOpen.statusCode}
      />
    </>
  );
};
export default UserCreateModal;
