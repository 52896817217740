import * as React from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DisplaySnackbar from '../../../../shared/Components/DisplaySnackbar';
import { useFormik } from "formik";
import { finances } from "../../../../shared/HTTP/HttpCalls";
import { snackbarData, snackbarStart } from '../../../../shared/Components/DisplaySnackbar';
import { FinanceReportSchema } from '../../../../shared/FormValidationSchema';

const ReportModal = (props) => {

  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);

  const onSubmit = (formData) => {
    finances
      .update(props.reportData._id, formData)
      .then((res) => {
        props.tableUpdater(props.rows.map(obj => obj._id === res.data._id ? res.data : obj));
        setSnackbarOpen(snackbarStart("success", "Record Updated", res.statusText, res.status));
      })
      .catch((err) => {
        setSnackbarOpen(snackbarStart("error", err.toString()));
      });

    props.handleClose();
    props.reportDialogClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      reason: props.reportData.reason
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: FinanceReportSchema
  });

  return (
    <>
      <Dialog open={props.open} onClose={() => { formik.resetForm(); props.reportDialogClose(); }}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Report
          <div id="icons" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}>
            <CloseIcon onClick={() => { formik.resetForm(); props.reportDialogClose(); }} />
          </div>
        </DialogTitle>

        <DialogContent>
          <TextField
            {...formik.getFieldProps("reason")}
            {...(formik.touched.reason && formik.errors.reason && { error: true })}
            helperText={(formik.touched.reason && formik.errors.reason) || " "}
            label="Reason"
            minRows={3}
            multiline
            variant="filled"
            sx={{ width: "100%", padding: "5px" }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            disabled={!(formik.dirty && formik.isValid)}
            onClick={formik.handleSubmit}
            type="submit"
            variant="contained"
            style={{ margin: "2px" }}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>

      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbarOpen.statusCode}
      />
    </>
  );
};

export default ReportModal;