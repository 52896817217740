import React from "react";
import { Button } from "@mui/material";
import { getUserId } from "../../../../shared/LocalStorage/LocalStorageCalls";
import { Attendances } from "../../../../shared/HTTP/HttpCalls";
import SignInOutDialog from "./SignInOutDialog"

const SignInOut = (props) => {

    const [ifSignedIn, setIfSignedIn] = React.useState(false);
    const [disabled, setDisabled] = React.useState({ disabled: false });
    const [isDialogOpen,setIsDialogOpen] = React.useState(false)

    React.useEffect(() => {
        Attendances.checkSignIn(getUserId())
            .then(res => {
                if (res.data.signIn && res.data.signOut) {
                    setDisabled({ disabled: true });
                } else if (res.data.signIn === false) {
                    setIfSignedIn(false);
                } else if (res.data.signOut === false) {
                    setIfSignedIn(true);
                }
            })
            .catch(err => console.log(err));
    }, [ifSignedIn]);

    const signInHandler = () => {
        if (ifSignedIn) {
            Attendances.signOut(getUserId())
                .then(res => setIfSignedIn(false))
                .catch(err => console.log(err));
        } else {
            Attendances.signIn(getUserId())
                .then(res => setIfSignedIn(true))
                .catch (err => console.log(err));
        }
        setIsDialogOpen(false)
    };

    const dialogHandler = (value) => {
        setIsDialogOpen(!isDialogOpen)
    }

return (
    <>
        <Button
            {...disabled}
            variant="contained"
            sx={{ width: "15%", height: "30%" }}
            style={disabled["disabled"] ? { backgroundColor: "#8e8c8c" } : ifSignedIn ? { backgroundColor: "red" } : { backgroundColor: "#2196f3" }}
            onClick={dialogHandler}
        >
            {
                ifSignedIn ?
                    "SIGN OUT"
                    :
                    "SIGN IN"
            }
        </Button>
        
        
        <SignInOutDialog isDialogOpen={isDialogOpen} dialogHandler={dialogHandler} signInHandler={signInHandler} ifSignedIn={ifSignedIn}/>
    </>
);
};
export default SignInOut;