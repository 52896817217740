import { Button, Dialog, DialogActions, DialogContent, DialogContentText,Stack } from '@mui/material'
import React from 'react'
import DeleteText from '../../../../shared/Components/DeleteText'
import DeleteUpdatePermission from '../../../../shared/Components/DeleteUpdatePermission'
import { faq } from '../../../../shared/HTTP/HttpCalls'

const DeleteDialog = (props) => {
    const handleClose = () => {
        props.setOpenDelete(false)
    }

    const deleteSingleData = () => {
        faq.delete(props.id)
            .then(res => {
                props.setDialogData([...props.dialogData.filter((_, index) => index !== res.data.id)])
                props.fetchFAQ()
                props.snackbarStart("success", `Question Deleted`, res.statusText, res.status);
            })
            .catch((err) => {
                props.snackbarStart("error", err.toString());
            })

        props.setOpenDelete(false)
    }

    return (
        <Stack data-testid="deleteDialog-component">
            <Dialog
                open={props.open}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogContentText style={{ color: "black" }} component={'div'}>
                        <DeleteText
                            access={props.access}
                            permission="delete"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DeleteUpdatePermission
                        handler={deleteSingleData}
                        access={props.access}
                        text="DELETE"
                        permission="delete"
                        color="red"
                    />
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        style={{marginLeft:"5px"}}
                    >
                        CANCEL
                    </Button>

                </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default DeleteDialog