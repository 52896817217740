import React from "react";
import { Route, Redirect } from "react-router-dom";
import { sidePanelRoute } from "../../../shared/global-constants";

const Dashboard = () => {
    return (
        <>
            <Route path="/">
                <Redirect to={sidePanelRoute.dashboardRoute}></Redirect>
            </Route>
            
            <div>
                <p>in dashboard</p>
            </div>
        </>
    );
};

export default Dashboard;