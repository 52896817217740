import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import { Attendances } from "../../../../shared/HTTP/HttpCalls";
import { Box, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";

function AttendanceRecord() {
  const [attendanceRecord, setAttendanceRecord] = React.useState([]);

  const onSubmit = (values) => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues: {
      monthYear: new Date(),
    },
    onSubmit,
  });

  const updateMonthYear = (date) => {
    formik.setFieldValue("monthYear", date);
  };

  useEffect(() => {
    async function fetchData() {
      const month = formik.values.monthYear.getMonth() + 1;
      const year = formik.values.monthYear.getFullYear();
      const response = await Attendances.getAttendanceCount(month, year);
      setAttendanceRecord(response.data);
    }
    fetchData();
  }, [formik.values.monthYear]);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        mt: "20px",
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ margin: 3, fontWeight: "bold" }}
      >
        Total Attendances
      </Typography>

      <Box
        sx={{
          maxWidth: 200,
          display: "flex",
          mb: 2,
          ml: 3,
          justifyContent: "flex-start",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            sx={{ minWidth: "40%", maxWidth: "100%", padding: "5px" }}
            views={["year", "month"]}
            inputFormat="MM-yyyy"
            {...formik.getFieldProps("monthYear")}
            value={formik.values.monthYear}
            onChange={(dateObj) => {
              updateMonthYear(dateObj);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Year and Month"
                name="dateAndMonth"
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ maxWidth: "sm", ml: "auto", mr: "auto" }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Designation</TableCell>
              <TableCell align="right">Attendances</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceRecord.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.role}</TableCell>
                <TableCell align="right">{row.attendances}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AttendanceRecord;
