import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Actions from "./Actions";
import Buttons from "./Buttons";
import "./styles.css";
import DeleteProjects from "./DeleteProjects";
import { project } from "../../../../shared/HTTP/HttpCalls";
import { useFormik } from "formik";
import { ProjectSchema } from "../../../../shared/FormValidationSchema";

const ProjectData = ({
  clientData,
  details,
  setOpen,
  fetchData,
  snackbarStart,
  access,
  open,
}) => {
  const [disabled, setDisabled] = React.useState({ disabled: true });
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [clientDetails, setClientDetails] = React.useState(details?.client);

  const editHandler = () => {
    if (disabled["disabled"] === true) {
      setDisabled({ disabled: false });
    } else {
      setDisabled({ disabled: true });
      formik.resetForm();
      setEdit(false);
    }
  };

  const removeDataHandler = () => {
    project
      .delete(details.id)
      .then((res) => {
        fetchData();
        snackbarStart("success", `Record Deleted`, res.statusText, res.status);
      })
      .catch((err) => {
        snackbarStart("error", err.toString());
      });
  };

  //UPDATE DATA HANDLER
  const updateDataHandler = () => {
    let technoFormat = [];
    for (let i = 0; i < formik.values.technologies.length; ++i) {
      technoFormat = [
        ...technoFormat,
        { technologies: formik.values.technologies[i] },
      ];
    }
    const request = {
      name: formik.values.name,
      description: formik.values.description,
      technologies: technoFormat,
      startdate: formik.values.startDate,
      enddate: formik.values.endDate,
      client: {
        _id: clientDetails.id,
        name: clientDetails.name,
      },
    };
    project
      .update(details.id, request)
      .then((res) => {
        fetchData();
        snackbarStart("success", `Record Updated`, res.statusText, res.status);
      })
      .catch((err) => {
        snackbarStart("error", err.toString());
      });
  };

  const formik = useFormik({
    initialValues: {
      name: details?.name,
      description: details?.description,
      startDate: details?.startdate,
      endDate: details?.enddate,
      client: details?.client?.name,
      clientData: clientData,
      technologies: details?.technologies.map((obj) => obj.technologies),
    },
    enableReinitialize: true,
    onSubmit: updateDataHandler,
    validationSchema: ProjectSchema,
  });

  return (
    <div data-testid="projectData">
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          formik.resetForm();
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <div
            style={{
              marginLeft: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography>{details && details.name}</Typography>
            </div>

            {/* ICONS -- EDIT , DELETE ,CLOSE*/}
            <div style={{ color: "grey" }}>
              <Actions
                setDeleteDialog={setDeleteDialog}
                setEdit={setEdit}
                setOpen={setOpen}
                editHandler={editHandler}
              />
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1 }}>
            <TextField
              {...disabled}
              {...formik.getFieldProps("name")}
              {...(formik.errors.name &&
                formik.touched.name && { error: true })}
              helperText={(formik.touched.name && formik.errors.name) || " "}
              label="Name"
              variant="filled"
            />
            <TextField
              {...disabled}
              {...formik.getFieldProps("description")}
              {...(formik.errors.description &&
                formik.touched.description && { error: true })}
              helperText={
                (formik.touched.description && formik.errors.description) || " "
              }
              label="Description"
              variant="filled"
            />
          </Stack>
          {/* TECHNOLOGY */}
          <Stack>
            <Autocomplete
              {...disabled}
              multiple
              id="technologies"
              {...formik.getFieldProps("technologies")}
              freeSolo={true}
              getOptionLabel={(option) => option}
              options={[]}
              onChange={(_, val) => formik.setFieldValue("technologies", val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...(formik.errors.technologies &&
                    formik.touched.technologies && { error: true })}
                  helperText={
                    (formik.touched.technologies &&
                      formik.errors.technologies) ||
                    "Press 'Enter' to seperate"
                  }
                  label="Technologies"
                  placeholder="Technologies"
                />
              )}
            />
          </Stack>
          {/* START AND END DATE */}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1 }}>
            <TextField
              {...disabled}
              {...formik.getFieldProps("startDate")}
              {...(formik.errors.startDate &&
                formik.touched.startDate && { error: true })}
              helperText={
                (formik.touched.startDate && formik.errors.startDate) || " "
              }
              type="date"
              label="Start Date"
              InputLabelProps={{ shrink: true }}
              variant="filled"
              sx={{ width: "100%" }}
            />
            <TextField
              {...disabled}
              {...formik.getFieldProps("endDate")}
              {...(formik.errors.endDate &&
                formik.touched.endDate && { error: true })}
              helperText={
                (formik.touched.endDate && formik.errors.endDate) || " "
              }
              type="date"
              label="End Date"
              InputLabelProps={{ shrink: true }}
              variant="filled"
              sx={{ width: "100%" }}
            />
          </Stack>
          {/*SELECT CLIENT NAME */}
          <Stack>
            <TextField
              {...disabled}
              {...formik.getFieldProps("client")}
              {...(formik.errors.client &&
                formik.touched.client && { error: true })}
              helperText={
                (formik.touched.client && formik.errors.client) || " "
              }
              id="client"
              label="Client Info"
              select
              variant="filled"
              required
            >
              {formik.values.clientData &&
                formik.values.clientData.map((data) => (
                  <MenuItem
                    onClick={() => {
                      setClientDetails(data);
                    }}
                    key={data._id}
                    value={data.name}
                  >
                    {data.name}
                  </MenuItem>
                ))}
            </TextField>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Buttons
            checkDisabled={() => {
              return !(formik.dirty && formik.isValid);
            }}
            handleSubmit={formik.handleSubmit}
            edit={edit}
            resetHandler={formik.resetForm}
            setOpen={setOpen}
            setEdit={setEdit}
            updateDataHandler={updateDataHandler}
            access={access}
          />
        </DialogActions>
      </Dialog>

      <DeleteProjects
        open={deleteDialog}
        setOpen={setOpen}
        setDeleteDialog={setDeleteDialog}
        removeDataHandler={removeDataHandler}
        access={access}
      />
    </div>
  );
};
export default ProjectData;
