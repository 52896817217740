import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw } from "draft-js";
import { faq } from "../../../../shared/HTTP/HttpCalls";
import DeleteUpdatePermission from "../../../../shared/Components/DeleteUpdatePermission";
import CloseIcon from "@mui/icons-material/Close";

const EditDialog = (props) => {
  const [plainText, setPlainText] = React.useState("");
  const [validSubmit, setValidSubmit] = React.useState(false);
  const [formData, setFormData] = React.useState({
    question: "",
    answer: "",
  });
  const [originalData, setOriginalData] = React.useState({
    question: "",
    answer: "",
  });

  React.useEffect(() => {
    setFormData({
      question: props.edit.question,
      answer: props.edit.answer,
    });
    setOriginalData({
      question: props.edit.question,
      answer: props.edit.answer,
    });
  }, [props.edit]);

  React.useEffect(() => {
    if (formData.question === "" || plainText.trim() === "") {
      setValidSubmit(false);
    } else if (JSON.stringify(formData) === JSON.stringify(originalData)) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [formData, originalData, plainText]);

  const handleClose = () => {
    props.setEditDialog(false);
    setFormData({});
    setOriginalData({});
    setPlainText("");
    setValidSubmit(false);
  };

  //update question value
  const handleQuestion = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  //HandleChange For  Editing Answers
  const handleChange = (prop) => (event) => {
    const value = JSON.stringify(convertToRaw(event.getCurrentContent()));
    setFormData((prev) => {
      return {
        ...prev,
        answer: value,
      };
    });
    setPlainText(event.getCurrentContent().getPlainText());
  };

  //Edit Handlers for updating data in database
  const editData = () => {
    const updates = {
      question: formData.question,
      answer: formData.answer,
    };
    faq
      .update(props.edit.id, updates)
      .then((res) => {
        props.fetchFAQ();
        props.snackbarStart(
          "success",
          `FAQ Updated`,
          res.statusText,
          res.status
        );
      })
      .catch((err) => {
        props.snackbarStart("error", err.toString());
      });
  };

  return (
    <div data-testid="editDialog-component">
      {/* DIALOG */}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          FAQ Info
          <div
            id="icons"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <CloseIcon onClick={handleClose} />
          </div>
        </DialogTitle>
        {/* DIALOG CONTENT */}
        <DialogContent>
          <Stack height={"100%"}>
            <TextField
              onChange={handleQuestion}
              value={formData.question}
              error={formData.question === "" ? true : false}
              helperText={(formData.question === "" && "Required") || " "}
              name="question"
              variant="filled"
              label="Enter Question"
              multiline
            />
            {/* RTE*/}
            <MUIRichTextEditor
              defaultValue={props.edit?.answer || " "}
              error={plainText === "" ? true : false}
              controls={[
                "title",
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "undo",
                "redo",
                "link",
                "media",
                "numberList",
                "bulletList",
                "code",
                "clear",
                "highlight",
              ]}
              onChange={handleChange("answer")}
            />
          </Stack>
        </DialogContent>
        {/* DIALOG ACTIONS*/}
        <DialogActions>
          <DeleteUpdatePermission
            disabled={!validSubmit}
            access={props.access}
            text="UPDATE"
            permission="update"
            handler={() => {
              editData();
              handleClose();
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default EditDialog;
