import { Button, DialogActions } from '@mui/material';
import React from 'react'
import DeleteUpdatePermission from '../../../../shared/Components/DeleteUpdatePermission';

const Buttons = (props) => {
  return (
    <div data-testid="buttonComponent">
      {
        //ACTIONS BUTTON
        props.edit ?
          <DialogActions>
            {/* reset button */}
            <Button
              onClick={() => props.resetHandler()}
              variant="contained"
            >
              RESET
            </Button>
            {/* Update button */}
            <DeleteUpdatePermission
              handler={() => { props.updateDataHandler(); props.setOpen(false); props.setEdit(false); props.handleSubmit() }}
              permission="update"
              text="SUBMIT"
              access={props.access}
              disabled={props.checkDisabled()}

            />
          </DialogActions>
          :
          <DialogActions>
            {/* Cancel button */}
            <Button
              disabled
              variant="contained"
            >
              SUBMIT
            </Button>
          </DialogActions>


      }
    </div>
  )
}

export default Buttons