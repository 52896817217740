import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { skills } from "../../../../shared/HTTP/HttpCalls"
import DeleteIcon from '@mui/icons-material/Delete';
import AddSkills from './AddSkills';
import DeleteSkills from './DeleteSkills';
import { Card, Rating } from '@mui/material';
import DisplaySnackbar from '../../../../shared/Components/DisplaySnackbar';
import { getSkillstorePermission } from '../../../../shared/Permission/permissionAccess';

const snackbarData = {
    open: false,
    severity: "",
    message: "",
    statusCode: ""
}

const Skillstores = () => {
    const [skill, setSkill] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [skillid, setSkillId] = React.useState({})
    const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);
    const access=getSkillstorePermission
    //FUNCTIONS

    const handleOpen = (params) => {
        setOpen(true)
        setSkillId(params.id)
    }
    //set snackbar values
    const snackbarStart = (severity, message, st = "", sc = "") => {
        setSnackbarOpen({
            open: true,
            message: message,
            severity: severity,
            statusCode: `${st} ${sc}`
        })
    };
    //COLUMNS
    const columns = [
        {
            field: 'name',
            headerName: 'Technology Name',
            width: 200,
            editable:false,
        },
        {
            field: 'currentscore',
            headerName: 'Current Score',
            width: 150,
            editable: false,
            renderCell: (params) => {
                return (
                    <Rating
                        name="read-only"
                        value={params.row.currentscore}
                        readOnly
                    />
                )
            }
        },
        {
            field: 'aspiringscore',
            headerName: 'Aspiring Score',
            width: 150,
            editable: false,
            renderCell: (params) => {
                return (
                    <Rating
                        name="read-only"
                        value={params.row.aspiringscore}
                        readOnly
                    />
                )
            }
        },
        {
            field: 'actions',
            headerName: 'Delete',
            width: 150,
            editable: true,
            renderCell: (params) => {
                return (
                    <DeleteIcon onClick={() => handleOpen(params.row)} style={{ color: "grey", cursor: "pointer" }} />
                )
            }
        },
    ]

    //FETCHING SKILLS
    const fetchSkills = () => {
        skills
            .findAll()
            .then(res => {
                setSkill(res.data)
            })
            .catch(err => console.log("Error:", err.message))
    }
    //USEEFFECT - - TO DISPLAY DATA
    React.useEffect(() => {
        fetchSkills()
    }, [])

    return (
        <div data-testid="skillstores-component" style={{ height: 400, width: '100%' }}>
            {/* SNACK BAR  */}
            <DisplaySnackbar
                open={snackbarOpen.open}
                closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
                message={snackbarOpen.message}
                severity={snackbarOpen.severity}
                statusCode={snackbarData.statusCode}
            />
            {/* DATA GRID TO SHOW DATA FROM API */}
            <AddSkills
                fetchSkills={fetchSkills}
                snackbarStart={snackbarStart}
                access={access}

            />
            {/* DELETE DATA */}
            <DeleteSkills
                skills={skills}
                skillid={skillid}
                open={open}
                setOpen={setOpen}
                fetchSkills={fetchSkills}
                snackbarStart={snackbarStart}
                access={access}
            />
            {/* DATA GRID */}
            <Card>
                <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                        rows={skill}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        disableSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-cell:hover': {
                                cursor: "pointer"
                            }
                        }}
                    />
                </div>
            </Card>
        </div>
    )
};
export default Skillstores;