import { Button } from '@mui/material'
import React from 'react'

const DeleteUpdatePermission = (props) => {
    return (
        <div data-testid="deleteUpdate-component">
            {
                props.access && props.access.filter(data => {
                    return data.type === props.permission
                }).map((data, index) => (
                    data.type && data.enabled ?
                        <Button
                            disabled={props.disabled}
                            key={index}
                            sx={{ background: props.color, color: "white", margin: "0px 3px" }}
                            variant="contained"
                            onClick={props.handler}
                            autoFocus
                        >
                            {props.text.toUpperCase()}
                        </Button>
                        :
                        <Button
                            key={index}
                            disabled
                            variant="contained"
                            sx={{ margin: "0px 3px" }}
                            autoFocus
                        >
                            {props.text.toUpperCase()}
                        </Button>
                ))
            }
        </div>
    );
};
export default DeleteUpdatePermission;