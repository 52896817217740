import { getPermission } from "../LocalStorage/LocalStorageCalls";
import { UserPermissions } from "../global-constants";

const access = JSON.parse(getPermission())
//Client Permission
export const getClientPermission = access && access
    .clients
    .map(data => {
        UserPermissions.client[data.type] = data.enabled
        return data;
    });

//ProjectPermission
export const getProjectPermission = access && access
    .projects
    .map(data => {
        UserPermissions.projects[data.type] = data.enabled
        return data;
    });

//SkillStore Permission 
export const getSkillstorePermission = access && access
    .skillstore
    .map(data => {
        UserPermissions.skillstore[data.type] = data.enabled
        return data;
    });

//Finance Permission 
export const getFinancePermission = access && access
    .reimbursements
    .map(data => {
        UserPermissions.finances[data.type] = data.enabled
        return data;
    });

//FAQ Permission 
export const getFAQPermission = access && access
    .faq
    .map(data => {
        UserPermissions.faq[data.type] = data.enabled
        return data;
    });

//Member Permission 
export const getMemberPermission = access && access
    .user
    .map(data => {
        UserPermissions.members[data.type] = data.enabled
        return data;
    });

//Attendance Permission 
export const getAttendancePermission = access && access
    .attendance
    .map(data => {
        UserPermissions.attendance[data.type] = data.enabled
        return data;
    });

//Leaves Permission 
export const getLeavesPermission = access && access
    .leaves
    .map(data => {
        UserPermissions.leave[data.type] = data.enabled
        return data;
    });

export const getKbPermission = access && access
    .kb
    .map(data => {
        UserPermissions.kb[data.type] = data.enabled
        return data;
    });