import React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import FinanceModal from "./FinanceModal";
import FinanceCreateModal from "./FinanceCreateModal";
import Stack from "@mui/material/Stack";
import { getFinancePermission } from "../../../../shared/Permission/permissionAccess";
import AddPermission from "../../../../shared/Components/AddPermission";
import { Media } from "../../../../shared/HTTP/HttpCalls";
import { apiURL } from "../../../../shared/global-constants";

const columns = [
  {
    field: "type",
    headerName: "Type",
    width: 200,
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 200,
  },
  {
    field: "transactionid",
    headerName: "Transaction Id",
    width: 200,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
  },
  {
    field: "reason",
    headerName: "Report",
    width: 200
  },
  {
    field: "filesrc",
    headerName: "View Files",
    renderCell: (cellValues) => {
      return (
        <Button
          disabled={!(cellValues.row.hasOwnProperty("filesrc") && cellValues.row.filesrc.length > 0)}
          variant="contained"
          onClick={(event) => {
            event.stopPropagation();
            OpenFile(cellValues.row.filesrc);
          }}
        >
          {cellValues.row.hasOwnProperty("filesrc") && cellValues.row.filesrc.length > 0 ? "View" : "N/A"}
        </Button>
      );
    }
  }
];

const OpenFile = async (filesrc) => {
  try {
    const DownloadedFile = await Media.findOne(filesrc);
    window.open(apiURL + DownloadedFile.data.url)
  } catch (err) {
    console.log(err);
  }
};

const FinanceTable = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogData, setDialogData] = React.useState({});
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const access = getFinancePermission
  const rowClickHandler = (param, event) => {
    setDialogOpen(true);
    setDialogData(param.row);
  };

  return (
    <>
      <Stack
        sx={{ mb: 2 }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 2, sm: 2, md: "1%" }}
      >
        <AddPermission
          handler={() => { setCreateDialogOpen(true) }}
          access={access}
          permission="create"
        />

      </Stack>
      <Card>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={props.rows}
            columns={columns}
            checkboxSelection
            pageSize={5}
            rowsPerPageOptions={[5]}
            initialState={{
              sorting: {
                sortModel: [{ field: "reason", sort: 'desc' }],
              },
            }}
            disableSelectionOnClick
            onRowClick={rowClickHandler}
            sx={{
              '& .MuiDataGrid-cell:hover': {
                cursor: "pointer"
              }
            }}
          />
        </div>
      </Card>
      <FinanceModal
        open={dialogOpen}
        dialogClose={() => { setDialogOpen(false) }}
        dialogData={dialogData}
        tableUpdater={props.tableUpdater}
        rows={props.rows}
        access={access}
      />
      <FinanceCreateModal
        open={createDialogOpen}
        dialogClose={() => { setCreateDialogOpen(false) }}
        tableUpdater={props.tableUpdater}
        rows={props.rows}
      />

    </>
  );
};
export default FinanceTable;