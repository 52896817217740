//API CALLS
export const apiURL = "https://panel.budhanatech.com/api";

export const API = {
  permissionsEndpoint: "/users-permissions/getpermission",
  rolesEndpoint: "/users-permissions/roles",
  userEndpoint: "/users",
  userMetaEndpoint: "/usermetas",

  uploadEndpoint: "/upload",
  mediaEndpoint: "/upload/files",

  userAuthEndpoint: "/auth/local",

  attendanceEndpoint: "/attendances",
  getPresentEndpoint: "/getpresent",
  signInEndpoint: "/signin",
  signOutEndpoint: "/signout",
  attendanceGetListEndpoint: "/attendances/getlist",
  checkSignInEndpoint: "/attendances/checksignin",
  getAttendanceCountEndpoint: "/attendances/getattendancecount",

  leavesEndpoint: "/leaves",
  leavesStatusEndpoint: "/leaves/status",
  leavesGetListEndpoint: "/getlist",
  leavesRemainingEndpoint: "/remainingleaves",
  kbEndpoint: "/kbs",

  clientEndpoint: "/clients",

  projectEndPoint: "/projects",

  skills: "/skillstores",

  financeEndpoint: "/reimbursements",

  faqEndPoint: "/faqs",

  careerEndPoint: "/careers",

  jobApplicationEndPoint: "/job-applications",
};

export let UserPermissions = {
  client: {},
  members: {},
  attendance: {},
  leave: {},
  projects: {},
  skillstore: {},
  finances: {},
  faq: {},
  kb: {},
};

export const values = {
  createPermission: "create",
  deletePermission: "delete",
  findPermission: "find",
  updatePermission: "update",
};

export const LocalStorageKeys = {
  token: "token",
  userid: "userid",
  roleid: "roleid",
  username: "username",
  email: "email",
  permision: "permision",
  role: "role",
  signedin: "signedin",
};

//side panel routes
export const sidePanelRoute = {
  dashboardRoute: "/dashboard",
  attendanceRoute: "/attendance",
  leaveRoute: "/leave",
  members: "/members",
  skills: "/skills",
  allProjects: "/allprojects",
  faq: "/faq",
  finances: "/finances",
  error: "/error",
  client: "/client",
  kb: "/kb",
};

//login components routes
export const AuthComponentRoutes = {
  homeRoute: "/auth",
};

export const STRINGS = {
  companyName: "BudhanaTech LLP",
  companyURL: "https://www.budhanatech.com/",
};

// recaptcha v2 keys
export const recaptchaSiteKey = "6LfVVE4iAAAAAPZd_or649C2cZHAH7UDdRZRLCJQ";

export const financeStatus = ["pending", "approved", "rejected"];

export const reimbursementsType = [
  "Salary",
  "Travel Allowance",
  "Gift",
  "Others",
];

// Feedback Link
export const feedbackURL =
  "https://docs.google.com/spreadsheets/d/1eoNdfSkHEgFKwE8H9Z4slbgUzTcCv-7llRYBgsIhhNc/edit#gid=0";

//Snackbar Position
export const vertical = "bottom";
export const horizontal = "center";

export const typeOfLeaves = ["paid", "unpaid", "sick", "maternal"];

export const leaveStatus = ["approved", "rejected", "pending"];

export const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const leaveTypesObj = {
  paid: "paid",
  unpaid: "unpaid",
  sick: "sick",
  maternal: "maternal",
}