import * as React from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DisplaySnackbar from "../../../../shared/Components/DisplaySnackbar";
import ReportModal from "./ReportModal";
import DeleteText from "../../../../shared/Components/DeleteText";
import DeleteUpdatePermission from "../../../../shared/Components/DeleteUpdatePermission";
import { useFormik } from "formik";
import { finances } from "../../../../shared/HTTP/HttpCalls";
import { financeStatus, reimbursementsType } from "../../../../shared/global-constants";
import { snackbarData, snackbarStart } from '../../../../shared/Components/DisplaySnackbar';
import { FinanceSchema } from '../../../../shared/FormValidationSchema';
import { Upload, Media } from "../../../../shared/HTTP/HttpCalls";

const FinanceModal = (props) => {

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [reportDialogOpen, setReportDialogOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState({ disabled: true });
  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);
  const [file, setFile] = React.useState([]);

  const onSubmit = async (formData) => {
    try {
      if (file.length !== 0) {
        if (props.dialogData.hasOwnProperty("filesrc") && props.dialogData.filesrc.length > 0) {
          Media.delete(props.dialogData.filesrc);
        }
        const fileUploaded = await Upload.create(file);
        formData["filesrc"] = fileUploaded.data[0]._id;
      }
      const financeUpload = await finances.update(props.dialogData._id, formData);
      props.tableUpdater(props.rows.map(obj => obj._id === financeUpload.data._id ? financeUpload.data : obj));
      setSnackbarOpen(snackbarStart("success", "Record Updated", financeUpload.statusText, financeUpload.status));
    } catch (err) {
      setSnackbarOpen(snackbarStart("error", err.toString()));
      console.log(err);
    }
    handleClose();
  };

  const deleteHandler = async () => {
    try {
      if (props.dialogData.hasOwnProperty("filesrc") && props.dialogData.filesrc.length > 0) {
        Media.delete(props.dialogData.filesrc);
      }
      const deleteResponse = await finances.delete(props.dialogData._id);
      props.tableUpdater(props.rows.filter((obj) => obj._id !== deleteResponse.data._id));
      setSnackbarOpen(snackbarStart("success", "Record Deleted", deleteResponse.statusText, deleteResponse.status));
    } catch (err) {
      setSnackbarOpen(snackbarStart("error", err.toString()));
      console.log(err);
    }
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      type: props.dialogData.type,
      amount: props.dialogData.amount,
      status: props.dialogData.status,
      transactionid: props.dialogData.transactionid,
      reason: props.dialogData.reason,
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: FinanceSchema
  });

  const handleFiles = (event) => {
    let fData = new FormData();
    fData.append(event.target.name, event.target.files[0]);
    setFile(fData);
  };

  const handleClose = () => {
    props.dialogClose();
    formik.resetForm();
    setDisabled({ disabled: true });
    setDeleteDialogOpen(false);
    setReportDialogOpen(false);
    setFile([]);
  };

  const editHandler = () => {
    if (disabled["disabled"] === true) {
      setDisabled({ disabled: false });
    }
    else {
      setDisabled({ disabled: true });
      formik.resetForm();
      setFile([]);
    }
  };

  return (
    <>
      {/*below is update dialog*/}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <div>Reimbursement Info</div>
          <div id="icons" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <DeleteIcon
              onClick={() => { setDeleteDialogOpen(true); setDisabled({ disabled: true }); formik.resetForm(); }}
              sx={{ margin: "0px 5px", "&:hover": { cursor: "pointer" } }}
            />
            <EditIcon
              onClick={editHandler}
              sx={{ margin: "0px 5px", "&:hover": { cursor: "pointer" } }}
            />
            <CloseIcon
              onClick={handleClose}
              sx={{ margin: "0px 5px", "&:hover": { cursor: "pointer" } }}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <div>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...formik.getFieldProps("type")}
                {...disabled}
                {... (formik.touched.type && formik.errors.type && { error: true })}
                helperText={(formik.touched.type && formik.errors.type) || " "}
                label="Type"
                select
                variant="filled"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              >
                {reimbursementsType.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                {...disabled}
                {...formik.getFieldProps("amount")}
                {... (formik.touched.amount && formik.errors.amount && { error: true })}
                helperText={(formik.touched.amount && formik.errors.amount) || " "}
                label="Amount"
                type="number"
                variant="filled"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              ></TextField>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("transactionid")}
                {... (formik.touched.transactionid && formik.errors.transactionid && { error: true })}
                helperText={(formik.touched.transactionid && formik.errors.transactionid) || " "}
                label="Transaction Id"
                variant="filled"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              >
              </TextField>

              <TextField
                {...disabled}
                {...formik.getFieldProps("status")}
                {... (formik.touched.status && formik.errors.status && { error: true })}
                helperText={(formik.touched.status && formik.errors.status) || " "}
                label="Status"
                select
                variant="filled"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              >
                {financeStatus.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <TextField
              {...disabled}
              {...formik.getFieldProps("reason")}
              {... (formik.touched.reason && formik.errors.reason && { error: true })}
              helperText={(formik.touched.reason && formik.errors.reason) || " "}
              label="Reason"
              minRows={3}
              multiline
              variant="filled"
              sx={{ width: "100%", padding: "5px" }}
            />
          </div>
        </DialogContent>

        <DialogActions
          style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", flexWrap: "wrap" }}
        >
          <DeleteUpdatePermission
            handler={() => { setReportDialogOpen(true); }}
            access={props.access}
            text="Report"
            permission="update"
            color="red"
          />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "2px" }}>

            <Button
              variant="contained"
              component="label"
              sx={{ margin: "2px" }}
              {...disabled}
            >
              {file.length !== 0 ? "ATTACHED" : props.dialogData.hasOwnProperty("filesrc") && props.dialogData.filesrc.length > 0 ? "OVERWRITE" : "ADD FILE"}
              <input
                id="files" name="files"
                type="file"
                onChange={handleFiles}
                hidden
              />
            </Button>

            <Button
              {...disabled}
              onClick={() => { formik.resetForm(); setFile([]); }}
              variant="contained"
              sx={{ margin: "2px" }}
            >
              RESET
            </Button>

            <DeleteUpdatePermission
              disabled={!(formik.dirty && formik.isValid) && file.length === 0}
              handler={formik.handleSubmit}
              access={props.access}
              text="Update"
              permission="update"
            />
          </div>
        </DialogActions>

      </Dialog>

      {/*below is delete dialog*/}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      >

        <DialogContent>
          <DeleteText
            access={props.access}
            permission="delete"
          />
        </DialogContent>

        <DialogActions>
          <DeleteUpdatePermission
            handler={deleteHandler}
            access={props.access}
            text="Delete"
            permission="delete"
            color="red"
          />
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
            }}
            variant="contained"
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>

      {/*below is report dialog*/}
      <ReportModal
        open={reportDialogOpen}
        reportDialogClose={() => { setReportDialogOpen(false); }}
        tableUpdater={props.tableUpdater}
        rows={props.rows}
        handleClose={handleClose}
        reportData={{
          reason: props.dialogData.reason,
          _id: props.dialogData._id,
        }}
      />

      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbarOpen.statusCode}
      />

    </>
  );
};
export default FinanceModal;