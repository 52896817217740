import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Dashboard from "../Pages/Navigation/Dashboard/Dashboard";
import Attendance from "../Pages/Navigation/Team/Attendance/Attendance";
import Leave from "../Pages/Navigation/Team/Leave/Leave";
import Members from "../Pages/Navigation/Team/AllTeam/Members";
import Skillstores from "../Pages/Navigation/Other/Skillstores/Skillstores";
import Projects from "../Pages/Navigation/Other/Projects/Projects";
import Finances from "../Pages/Navigation/Other/Finances/Finances";
import KnowledgeBase from "../Pages/Navigation/Other/KB/KnowledgeBase";

import { sidePanelRoute } from "../shared/global-constants";
import FAQ from "../Pages/Navigation/Other/FAQ/FAQ";
import { getAttendancePermission, getClientPermission, getFAQPermission, getFinancePermission, getLeavesPermission, getMemberPermission, getProjectPermission, getSkillstorePermission, getKbPermission } from "../shared/Permission/permissionAccess";
import Clients from "../Pages/Navigation/Other/Clients/Clients";

const Content = () => {
  const projectAccess = getProjectPermission;
  const skillstoreAccess = getSkillstorePermission;
  const financeAccess = getFinancePermission;
  const faqAccess = getFAQPermission;
  const userAccess = getMemberPermission;
  const leavesAccess = getLeavesPermission;
  const attendanceAccess = getAttendancePermission;
  const clientAccess = getClientPermission;
  const kbAccess = getKbPermission;

  return (
    <Switch>

      {/* DASHBOARD ROUTE */}
      <Route path={sidePanelRoute.dashboardRoute}>
        <Dashboard />
      </Route>
      {/* CLIENT ROUTE */}
      {
        clientAccess && clientAccess.map((data, index) => {
          return data.type === "count" && data.enabled === true ?
            <Route key={index} path={sidePanelRoute.client}>
              <Clients />
            </Route>
            :
            <Route key={index} path={sidePanelRoute.dashboardRoute}>
              <Redirect to={sidePanelRoute.dashboardRoute} />
            </Route>
        })
      }
      {/* MEMBER ROUTE */}
      {
        userAccess && userAccess.map((data, index) => {
          return data.type === "count" && data.enabled === true ?
            <Route key={index} path={sidePanelRoute.members}>
              <Members />
            </Route>
            :
            <Route key={index} path={sidePanelRoute.dashboardRoute}>
              <Redirect to={sidePanelRoute.dashboardRoute} />
            </Route>
        })
      }

      {/* ATTENDANCE ROUTE */}
      {
        attendanceAccess && attendanceAccess.map((data, index) => {
          return data.type === "count" && data.enabled === true ?
            <Route key={index} path={sidePanelRoute.attendanceRoute}>
              <Attendance />
            </Route>
            :
            <Route key={index} path={sidePanelRoute.dashboardRoute}>
              <Redirect to={sidePanelRoute.dashboardRoute} />
            </Route>
        })
      }

      {/* LEAVES ROUTE */}
      {
        leavesAccess && leavesAccess.map((data, index) => {
          return data.type === "count" && data.enabled === true ?
            <Route key={index} path={sidePanelRoute.leaveRoute}>
              <Leave />
            </Route>
            :
            <Route key={index} path={sidePanelRoute.dashboardRoute}>
              <Redirect to={sidePanelRoute.dashboardRoute} />
            </Route>
        })
      }

      {/*KNOWLEDGE BASE ROUTE*/}
      {
        kbAccess && kbAccess.map((data, index) => {
          return data.type === "count" && data.enabled === true ?
            <Route key={index} path={sidePanelRoute.kb}>
              <KnowledgeBase />
            </Route>
            :
            <Route key={index} path={sidePanelRoute.dashboardRoute}>
              <Redirect to={sidePanelRoute.dashboardRoute} />
            </Route>
        })
      }

      {/* PROJECTS ROUTE */}
      {
        projectAccess && projectAccess.map((data, index) => {
          return data.type === "count" && data.enabled === true ?
            <Route key={index} path={sidePanelRoute.allProjects}>
              <Projects />
            </Route>
            :
            <Route key={index} path={sidePanelRoute.dashboardRoute}>
              <Redirect to={sidePanelRoute.dashboardRoute} />
            </Route>
        })
      }
      {/* SKILLSTORES ROUTE */}
      {
        skillstoreAccess && skillstoreAccess.map((data, index) => {
          return data.type === "count" && data.enabled === true ?
            <Route key={index} path={sidePanelRoute.skills}>
              <Skillstores />
            </Route>
            :
            <Route key={index} path={sidePanelRoute.dashboardRoute}>
              <Redirect to={sidePanelRoute.dashboardRoute} />
            </Route>
        })
      }

      {/* FINANCE ROUTE */}
      {
        financeAccess && financeAccess.map((data, index) => {
          return data.type === "count" && data.enabled === true ?
            <Route key={index} path={sidePanelRoute.finances}>
              <Finances />
            </Route>
            :
            <Route key={index} path={sidePanelRoute.dashboardRoute}>
              <Redirect to={sidePanelRoute.dashboardRoute} />
            </Route>
        })
      }

      {/* FAQ ROUTE */}
      {
        faqAccess && faqAccess.map((data, index) => {
          return data.type === "count" && data.enabled === true ?
            <Route key={index} path={sidePanelRoute.faq}>
              <FAQ />
            </Route>
            :
            <Route key={index} path={sidePanelRoute.dashboardRoute}>
              <Redirect to={sidePanelRoute.dashboardRoute} />
            </Route>
        })
      }

      {/* RANDOM ROUTE */}
      <Route path="*">
        <Redirect to={sidePanelRoute.dashboardRoute}></Redirect>
      </Route>

    </Switch>
  );
}

export default Content;