import * as React from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DisplaySnackbar from "../../../../shared/Components/DisplaySnackbar";
import DeleteText from "../../../../shared/Components/DeleteText";
import DeleteUpdatePermission from "../../../../shared/Components/DeleteUpdatePermission";
import { useFormik } from "formik";
import { users } from "../../../../shared/HTTP/HttpCalls";
import {
  snackbarData,
  snackbarStart,
} from "../../../../shared/Components/DisplaySnackbar";
import { MemberUpdateSchema } from "../../../../shared/FormValidationSchema";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { mapUserWithMeta } from "../../../../shared/utils";

const UserModal = (props) => {
  const [disabled, setDisabled] = React.useState({ disabled: true });
  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const onSubmit = (formData) => {
    // delete undefined fields from formData
    Object.keys(formData).forEach(
      (key) => formData[key] === undefined && delete formData[key]
    );
    // add meta id to formData because it is required by backend to update user meta
    formData.metaid = props.dialogData.metaid;
    users
      .update(props.dialogData._id, formData)
      .then((res) => {
        res.data = mapUserWithMeta(res.data);
        props.tableUpdater(
          props.rows.map((obj) => {
            return obj._id === res.data._id ? res.data : obj;
          })
        );
        setSnackbarOpen(
          snackbarStart("success", "Record Added", res.statusText, res.status)
        );
      })
      .catch((err) => setSnackbarOpen(snackbarStart("error", err.toString())));
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      username: props.dialogData.username,
      mobileno: props.dialogData.mobileno,
      email: props.dialogData.email,
      role: props.dialogData.role,
      rolesData: props.rolesData.roles,
      designation: props.dialogData.designation,
      empid: props.dialogData.empid,
      doj: props.dialogData.doj,
      experience: props.dialogData.experience,
      probation: props.dialogData.probation,
      probationenddate: props.dialogData.probationenddate,
      internship: props.dialogData.internship,
      internshipenddate: props.dialogData.internshipenddate,
      name: props.dialogData.name,
      dob: props.dialogData.dob,
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: MemberUpdateSchema,
  });

  const handleClose = () => {
    setDisabled({ disabled: true });
    setDeleteDialogOpen(false);
    props.dialogClose();
    formik.resetForm();
  };

  const editHandler = () => {
    if (disabled["disabled"] === true) {
      setDisabled({ disabled: false });
    } else {
      setDisabled({ disabled: true });
      formik.resetForm();
    }
  };

  const deleteHandler = () => {
    users
      .delete(props.dialogData._id)
      .then((res) => {
        props.tableUpdater(
          props.rows.filter((obj) => obj._id !== res.data._id)
        );
      })
      .catch((err) => console.log(err));

    handleClose();
  };
  return (
    <>
      {/*below is update dialog*/}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          {props.dialogData.username}
          <div
            id="icons"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <DeleteIcon
              onClick={() => {
                setDeleteDialogOpen(true);
                setDisabled({ disabled: true });
                formik.resetForm();
              }}
              sx={{ margin: "0px 5px", cursor: "pointer" }}
            />
            <EditIcon
              onClick={editHandler}
              sx={{ margin: "0px 5px", cursor: "pointer" }}
            />
            <CloseIcon
              onClick={handleClose}
              sx={{ margin: "0px 5px", cursor: "pointer" }}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <div>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("name")}
                {...(formik.touched.name &&
                  formik.errors.name && { error: true })}
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
                helperText={(formik.touched.name && formik.errors.name) || " "}
                label="User Name"
                name="name"
                variant="filled"
              ></TextField>

              <TextField
                {...disabled}
                {...formik.getFieldProps("role")}
                {...(formik.touched.role &&
                  formik.errors.role && { error: true })}
                helperText={(formik.touched.role && formik.errors.role) || " "}
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
                select
                label="Role"
                variant="filled"
              >
                {formik.values.rolesData &&
                  formik.values.rolesData.map((role) => (
                    <MenuItem key={role._id} value={role._id}>
                      {role.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("mobileno")}
                {...(formik.touched.mobileno &&
                  formik.errors.mobileno && { error: true })}
                helperText={
                  (formik.touched.mobileno && formik.errors.mobileno) || " "
                }
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
                label="Mobile no."
                variant="filled"
              ></TextField>

              <TextField
                {...disabled}
                {...formik.getFieldProps("email")}
                {...(formik.touched.email &&
                  formik.errors.email && { error: true })}
                helperText={
                  (formik.touched.email && formik.errors.email) || " "
                }
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
                label="Email"
                variant="filled"
              />
            </Stack>

            <Typography>Additional Info: </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("empid")}
                {...(formik.touched.empid &&
                  formik.errors.empid && { error: true })}
                helperText={
                  (formik.touched.empid && formik.errors.empid) || " "
                }
                sx={{ width: "100%", padding: "5px" }}
                label="Employee Id"
                variant="filled"
              />
              <TextField
                {...disabled}
                {...formik.getFieldProps("username")}
                {...(formik.touched.username &&
                  formik.errors.username && { error: true })}
                helperText={
                  (formik.touched.username && formik.errors.username) || " "
                }
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
                id="username"
                name="username"
                label="Full Name"
                variant="filled"
              ></TextField>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("designation")}
                {...(formik.touched.designation &&
                  formik.errors.designation && { error: true })}
                helperText={
                  (formik.touched.designation && formik.errors.designation) ||
                  " "
                }
                sx={{ width: "100%", padding: "5px" }}
                label="Designation"
                variant="filled"
              />
              <TextField
                {...disabled}
                {...formik.getFieldProps("doj")}
                {...(formik.touched.doj &&
                  formik.errors.doj && { error: true })}
                helperText={(formik.touched.doj && formik.errors.doj) || " "}
                sx={{ width: "100%", padding: "5px" }}
                type="date"
                label="Date of Joining"
                variant="filled"
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("experience")}
                {...(formik.touched.experience &&
                  formik.errors.experience && { error: true })}
                helperText={
                  (formik.touched.experience && formik.errors.experience) || " "
                }
                sx={{ width: "100%", padding: "5px" }}
                label="Experience"
                variant="filled"
                type="number"
              />
              <FormControlLabel
                {...disabled}
                {...formik.getFieldProps("probation")}
                {...(formik.touched.probation &&
                  formik.errors.probation && { error: true })}
                control={<Checkbox checked={formik.values.probation} />}
                label="Probation"
                name="probation"
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("probationenddate")}
                {...(formik.touched.probationenddate &&
                  formik.errors.probationenddate && { error: true })}
                helperText={
                  (formik.touched.probationenddate &&
                    formik.errors.probationenddate) ||
                  " "
                }
                sx={{ width: "100%", padding: "5px" }}
                label="Probation End Date"
                variant="filled"
                type="date"
              />
              <FormControlLabel
                {...disabled}
                {...formik.getFieldProps("internship")}
                {...(formik.touched.internship &&
                  formik.errors.internship && { error: true })}
                control={<Checkbox checked={formik.values.internship} />}
                label="Internship"
                name="internship"
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("internshipenddate")}
                {...(formik.touched.internshipenddate &&
                  formik.errors.internshipenddate && { error: true })}
                helperText={
                  (formik.touched.internshipenddate &&
                    formik.errors.internshipenddate) ||
                  " "
                }
                sx={{ width: "100%", padding: "5px" }}
                label="Internship End Date"
                variant="filled"
                type="date"
              />
              <TextField
                {...disabled}
                {...formik.getFieldProps("dob")}
                {...(formik.touched.dob &&
                  formik.errors.dob && { error: true })}
                helperText={(formik.touched.dob && formik.errors.dob) || " "}
                sx={{ width: "100%", padding: "5px" }}
                label="Date of Birth"
                variant="filled"
                type="date"
                required
              />
            </Stack>
          </div>
        </DialogContent>

        <DialogActions>
          <Button {...disabled} onClick={formik.resetForm} variant="contained">
            RESET
          </Button>
          <DeleteUpdatePermission
            disabled={!(formik.dirty && formik.isValid)}
            access={props.access}
            handler={formik.handleSubmit}
            text="Update"
            permission="update"
          />
        </DialogActions>
      </Dialog>

      {/*below is delete dialog*/}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      >
        <DialogTitle>Delete</DialogTitle>

        <DialogContent>
          <DialogContentText component={"div"}>
            <DeleteText access={props.access} permission="destroy" />
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <DeleteUpdatePermission
            handler={() => {
              deleteHandler();
            }}
            access={props.access}
            text="Delete"
            permission="destroy"
            color="red"
          />
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
            }}
            variant="contained"
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>

      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => {
          setSnackbarOpen({ open: false, severity: "", message: "" });
        }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbarOpen.statusCode}
      />
    </>
  );
};
export default UserModal;
