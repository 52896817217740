import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { useFormik } from "formik";
import AddPermission from '../../../../shared/Components/AddPermission';
import CloseIcon from "@mui/icons-material/Close";
import { faq } from '../../../../shared/HTTP/HttpCalls';
import { faqQuesSchema } from '../../../../shared/FormValidationSchema';

const AddQuestions = (props) => {

    const onSubmit = (formData) => {
        const toSend = {
            question: formData.question,
            users_permissions_user: {
                _id: props.userId,
                role: props.role,
                id: props.userId
            },
            answer: ""
        };
        faq.create(toSend)
            .then(res => {
                props.fetchFAQ();
                props.snackbarStart("success", `Question Added`, res.statusText, res.status);
            })
            .catch((err) => {
                props.snackbarStart("error", err.toString());
            });
        handleClose();
    };

    const formik = useFormik({
        initialValues: {
            question: ""
        },
        onSubmit,
        validationSchema: faqQuesSchema
    });

    const handleClose = () => {
        props.setAddDialog(false)
        formik.resetForm();
    }

    return (
        <div data-testid="addQuestion-component">
            {/*Dialog */}
            <Dialog
                open={props.open}
                onClose={handleClose}
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                    FAQ Info
                    <div
                        id="icons"
                        style={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}
                    >
                        <CloseIcon onClick={handleClose} />
                    </div>
                </DialogTitle>
                {/*Dialog Content */}
                <DialogContent>
                    <Stack >
                        <TextField
                            {...formik.getFieldProps("question")}
                            {...(formik.touched.question && formik.errors.question && { error: true })}
                            helperText={(formik.touched.question && formik.errors.question) || " "}
                            variant='filled'
                            label="Enter Question*"
                            multiline
                        />
                    </Stack>
                </DialogContent>
                {/*Dialog Actions */}
                <DialogActions>
                    <Button
                        onClick={formik.handleSubmit}
                        disabled={!(formik.dirty && formik.isValid)}
                        variant="contained"
                        autoFocus
                    >
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Floating button */}
            <AddPermission
                access={props.access}
                handler={() => { props.setAddDialog(true) }}
                permission="create"
            />
        </div>
    );
};
export default AddQuestions;