import React from "react";
import { finances } from "../../../../shared/HTTP/HttpCalls";
import { getFinancePermission } from "../../../../shared/Permission/permissionAccess";

import FinanceTable from "./FinanceTable";
import ReceiptGenerateModal from "./ReceiptGenerateModal";
const Finances = () => {
  const [tableData, setTableData] = React.useState([]);
  const [showReceiptGenerate, setShowReceiptGenerate] = React.useState(false);
  React.useEffect(() => {
    finances
      .findAll()
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => console.log("error: ", err));

    // here we are calling the function to get the permission and update the state so on that basis we can show the receipt generate button
    setShowReceiptGenerate(
      getFinancePermission.some(
        (permission) =>
          permission.type === "create" && permission.enabled === true
      )
    );
  }, []);

  const tableUpdater = (newData) => {
    setTableData(newData);
  };

  return (
    <div>
      {showReceiptGenerate && <ReceiptGenerateModal />}
      <FinanceTable rows={tableData} tableUpdater={tableUpdater} />
    </div>
  );
};

export default Finances;
