import React, { useState, useRef, useCallback } from "react";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import { useReactToPrint } from "react-to-print";
import {
  calculateLeaveTotals,
  getCurrentMonthLeavesAndTotalDays,
  getTotalDaysInMonth,
  handleSalaryCalculation,
} from "./handleCalculations";
import ReceiptFile from "./ReceiptFile";
import { ReceiptGenerateSchema } from "../../../../shared/FormValidationSchema";
import { users } from "../../../../shared/HTTP/HttpCalls";
import { useEffect } from "react";

const ReceiptGenerateModal = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const receiptRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => reactToPrintContent(),
    pageStyle: `
      @media print {
      @page {  size: A4 portrait }
    margin: 5mm auto;
      }
  `,
  });

  const reactToPrintContent = useCallback(() => {
    return receiptRef.current;
  }, []);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const onSubmit = async () => {
    handlePrint();
    handleClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      usermeta: {},
      totalSalary: 0,
      basic: 0,
      hra: 0,
      other: 0,
      gross: 0,
      professionalTax: 200,
      tds: 0,
      unpaid: 0,
      additional: 0,
      deduction: 0,
      net: 0,
      totalDays: getTotalDaysInMonth(new Date()),
      monthYear: new Date(),
      currentMonthUsedTotalDays: {},
      currentMonthUsedLeaves: {},
    },
    onSubmit,
    validationSchema: ReceiptGenerateSchema,
  });

  const handleTotal = (totalSalary) => {
    const salaryCalculation = handleSalaryCalculation(
      totalSalary,
      formik.values.monthYear,
      selectedUser.leaves
    );

    const { totalDays } = getCurrentMonthLeavesAndTotalDays(
      formik.values.monthYear,
      selectedUser.leaves
    );

    formik.setFieldValue("currentMonthUsedTotalDays", totalDays);

    formik.setFieldValue("totalSalary", totalSalary);
    formik.setFieldValue("basic", salaryCalculation.basic);
    formik.setFieldValue("hra", salaryCalculation.hra);
    formik.setFieldValue("other", salaryCalculation.other);
    formik.setFieldValue("gross", salaryCalculation.gross);
    formik.setFieldValue(
      "net",
      salaryCalculation.gross - formik.values.professionalTax
    );
    formik.setFieldValue("unpaid", salaryCalculation.unpaid);
  };

  const handleDeduction = (e) => {
    const { value: deductionAmount } = e.target;
    const totalDeduction =
      Number(formik.values.gross) -
      Number(formik.values.professionalTax) -
      Number(formik.values.tds) -
      Number(deductionAmount);

    formik.setFieldValue("deduction", deductionAmount);
    formik.setFieldValue("net", totalDeduction);
  };

  const handleAdditional = (e) => {
    const { value: additionalAmount } = e.target;
    const totalEarning =
      Number(formik.values.gross) +
      Number(additionalAmount) -
      (Number(formik.values.tds) +
        Number(formik.values.deduction) +
        Number(formik.values.professionalTax));
    formik.setFieldValue("additional", additionalAmount);
    formik.setFieldValue("net", totalEarning);
  };

  const handleTDS = (e) => {
    const { value: tdsAmount } = e.target;
    if (formik.values.gross) {
      const totalTDS =
        Number(formik.values.gross) +
        Number(formik.values.additional) -
        Number(formik.values.deduction) -
        Number(formik.values.professionalTax) -
        Number(tdsAmount);
      formik.setFieldValue("tds", tdsAmount);
      formik.setFieldValue("net", totalTDS);
    }
  };

  const updateMonthYear = (dateObj) => {
    const totalDaysInSelectedMonth = getTotalDaysInMonth(dateObj);

    formik.setFieldValue("monthYear", dateObj, true);
    formik.setFieldValue("totalDays", totalDaysInSelectedMonth);

    if (selectedUser.leaves) {
      const currentMonthUsedLeaves = calculateLeaveTotals(
        dateObj,
        selectedUser.leaves
      );
      formik.setFieldValue("currentMonthUsedLeaves", currentMonthUsedLeaves);

      const { totalDays } = getCurrentMonthLeavesAndTotalDays(
        dateObj,
        selectedUser.leaves
      );
      formik.setFieldValue("currentMonthUsedTotalDays", totalDays);

      if (formik.values.totalSalary > 0) {
        const salaryCalculation = handleSalaryCalculation(
          formik.values.totalSalary,
          dateObj,
          selectedUser.leaves
        );
        formik.setFieldValue("gross", salaryCalculation.gross);
        formik.setFieldValue("unpaid", salaryCalculation.unpaid);
        formik.setFieldValue(
          "net",
          salaryCalculation.gross - formik.values.professionalTax
        );
      }
    }
  };

  const handleSelectUser = (selectedUser) => {
    const userInfo = usersData.filter(
      (user) => selectedUser === user.usermeta.name
    );
    if (userInfo.length > 0) {
      const currentMonthUsedLeaves = calculateLeaveTotals(
        formik.values.monthYear,
        userInfo[0].leaves
      );
      setSelectedUser(userInfo[0]);
      formik.setFieldValue("currentMonthUsedLeaves", currentMonthUsedLeaves);
      formik.setFieldValue("usermeta", userInfo[0].usermeta);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await users
        .findAll()
        .then((res) => {
          setUsersData(res.data);
        })
        .catch((err) => console.log("error: ", err));
    };
    fetchData();
  }, []);

  return (
    <>
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          startIcon={<ReceiptIcon />}
          onClick={handleClickOpen}
        >
          Generate PaySlip
        </Button>
      </Stack>

      <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Generate PaySlip
          <div id="icons" style={{ display: "flex", alignItems: "center" }}>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ margin: "8px 0px" }}
            >
              <Autocomplete
                freeSolo
                options={usersData.map((user) => user.usermeta.name)}
                sx={{ minWidth: "60%", maxWidth: "100%", padding: "0px 5px" }}
                onChange={(e, newValue) => {
                  handleSelectUser(newValue);
                  formik.setFieldValue("name", newValue);
                }}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...(formik.touched.name &&
                      formik.errors.name && { error: true })}
                    helperText={
                      (formik.touched.name && formik.errors.name) || " "
                    }
                    {...params}
                    name="name"
                    label="Employee Name"
                  />
                )}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={{ minWidth: "40%", maxWidth: "100%", padding: "5px" }}
                  views={["year", "month"]}
                  inputFormat="MM-yyyy"
                  {...formik.getFieldProps("monthYear")}
                  value={formik.values.monthYear}
                  onChange={(dateObj) => {
                    updateMonthYear(dateObj);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Year and Month"
                      name="dateAndMonth"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mt: 2 }}
            >
              <TextField
                disabled={!formik.values.name}
                label="Total Salary"
                type="tel"
                name="totalSalary"
                sx={{
                  minWidth: "33.33%",
                  maxWidth: "100%",
                  padding: "0px 5px",
                }}
                {...formik.getFieldProps("totalSalary")}
                {...(formik.touched.totalSalary &&
                  formik.errors.totalSalary && { error: true })}
                helperText={
                  (formik.touched.totalSalary && formik.errors.totalSalary) ||
                  " "
                }
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => handleTotal(e.target.value)}
              />
              <TextField
                disabled
                label="Basic"
                name="basic"
                type="tel"
                sx={{
                  minWidth: "33.33%",
                  maxWidth: "100%",
                  padding: "0px 5px",
                }}
                {...formik.getFieldProps("basic")}
              />
              <TextField
                disabled
                label="HRA"
                name="hra"
                type="tel"
                sx={{ minWidth: "33.33%", maxWidth: "100%", padding: "5px" }}
                {...formik.getFieldProps("hra")}
                {...(formik.touched.hra &&
                  formik.errors.hra && { error: true })}
                helperText={(formik.touched.hra && formik.errors.hra) || " "}
              />
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                disabled
                label="Other Allowance"
                name="other"
                type="tel"
                sx={{
                  minWidth: "33.33%",
                  maxWidth: "100%",
                  padding: "0px 5px",
                }}
                {...formik.getFieldProps("other")}
                {...(formik.touched.other &&
                  formik.errors.other && { error: true })}
                helperText={
                  (formik.touched.other && formik.errors.other) || " "
                }
              />
              <TextField
                disabled
                label="Unpaid Leaves"
                type="tel"
                name="unpaid"
                sx={{ minWidth: "33.33%", maxWidth: "100%", padding: "5px" }}
                {...formik.getFieldProps("unpaid")}
                {...(formik.touched.unpaid &&
                  formik.errors.unpaid && { error: true })}
                helperText={
                  (formik.touched.unpaid && formik.errors.unpaid) || " "
                }
                value={formik.values.unpaid}
                InputProps={{ inputProps: { min: 0 } }}
              />
              <TextField
                disabled
                label="Gross Salary"
                name="gross"
                type="tel"
                sx={{ minWidth: "33.33%", maxWidth: "100%", padding: "5px" }}
                {...formik.getFieldProps("gross")}
              />
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                disabled
                label="ProfessionalTax"
                type="tel"
                name="professionalTax"
                sx={{ minWidth: "33.33%", maxWidth: "100%", padding: "5px" }}
                {...formik.getFieldProps("professionalTax")}
                {...(formik.touched.professionalTax &&
                  formik.errors.professionalTax && { error: true })}
                helperText={
                  (formik.touched.professionalTax &&
                    formik.errors.professionalTax) ||
                  " "
                }
              />
              <TextField
                label="TDS"
                type="tel"
                name="tds"
                sx={{ minWidth: "33.33%", maxWidth: "100%", padding: "5px" }}
                {...formik.getFieldProps("tds")}
                {...(formik.touched.tds &&
                  formik.errors.tds && { error: true })}
                helperText={(formik.touched.tds && formik.errors.tds) || " "}
                onChange={handleTDS}
                InputProps={{ inputProps: { min: 0 } }}
              />
              <TextField
                label="Additional Deductions"
                type="tel"
                name="deduction"
                sx={{ minWidth: "33.33%", maxWidth: "100%", padding: "5px" }}
                {...formik.getFieldProps("deduction")}
                {...(formik.touched.deduction &&
                  formik.errors.deduction && { error: true })}
                helperText={
                  (formik.touched.deduction && formik.errors.deduction) || " "
                }
                onChange={handleDeduction}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                label="Additional Earnings"
                type="tel"
                name="additional"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
                {...formik.getFieldProps("additional")}
                {...(formik.touched.additional &&
                  formik.errors.additional && { error: true })}
                helperText={
                  (formik.touched.additional && formik.errors.additional) || " "
                }
                onChange={handleAdditional}
                InputProps={{ inputProps: { min: 0 } }}
              />
              <TextField
                disabled
                label="Net Salary"
                type="tel"
                name="net"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
                {...formik.getFieldProps("net")}
              />
            </Stack>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={!(formik.isValid && formik.dirty)}
          >
            Download
          </Button>
        </DialogActions>

        <div style={{ display: "none" }}>
          <ReceiptFile ref={receiptRef} formData={formik.values} />
        </div>
      </Dialog>
    </>
  );
};

export default ReceiptGenerateModal;
