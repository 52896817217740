import React from "react";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";

import LeaveUpdateDialog from "./LeaveUpdateDialog";
import LeaveCreateDialog from "./LeaveCreateDialog";
import AddPermission from "../../../../shared/Components/AddPermission";
import { getLeavesPermission } from "../../../../shared/Permission/permissionAccess";

const columns = [
  {
    field: "username",
    headerName: "Name",
    width: 250,
    renderCell: (params) => {
      return (
        <div className="rowitem">
          {params.row.users_permissions_user?.username}
        </div>
      );
    },
  },
  {
    field: "type",
    headerName: "Leave Type",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "fromdate",
    headerName: "From",
    width: 150,
  },
  {
    field: "todate",
    headerName: "To",
    width: 150,
  },
];

const LeaveTable = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogData, setDialogData] = React.useState({});
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

  const access = getLeavesPermission;
  const LeaveDialogRowClickHandler = (param, event) => {
    setDialogOpen(true);
    setDialogData({
      ...param.row,
      username: param.row.users_permissions_user.username,
    });
  };
  return (
    <>
      {/*create new leave req. dialog*/}
      <LeaveCreateDialog
        open={createDialogOpen}
        dialogClose={() => {
          setCreateDialogOpen(false);
        }}
        tableUpdater={props.tableUpdater}
        rows={props.rows}
        remainingLeaves={props.remainingLeaves}
      />

      {/*table*/}
      <Card>
        <div style={{ height: 630, width: "100%" }}>
          <DataGrid
            checkboxSelection
            rows={props.rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            onRowClick={LeaveDialogRowClickHandler}
            sx={{
              "& .MuiDataGrid-cell:hover": {
                cursor: "pointer",
              },
            }}
          />
        </div>
      </Card>

      {/*update req dialog*/}
      <LeaveUpdateDialog
        open={dialogOpen}
        dialogClose={() => {
          setDialogOpen(false);
        }}
        dialogData={dialogData}
        tableUpdater={props.tableUpdater}
        rows={props.rows}
        access={access}
        remainingLeaves={props.remainingLeaves}
      />

      {/*foating add button*/}
      <AddPermission
        handler={() => {
          setCreateDialogOpen(true);
        }}
        access={access}
        permission="create"
      />
    </>
  );
};
export default LeaveTable;
