import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import React from 'react'
import DeleteText from '../../../../shared/Components/DeleteText'
import DeleteUpdatePermission from '../../../../shared/Components/DeleteUpdatePermission'

const DeleteSkills = (props) => {
    const handleDelete = () => {
        props.skills
            .delete(props.skillid)
            .then(res => {
                props.fetchSkills()
                props.snackbarStart("success", `Record Deleted`, res.statusText, res.status);
            })
            .catch((err) => {
                props.snackbarStart("error", err.toString());
            })
    }
    return (
        <div data-testid="deleteskills-component">
            {/* DELETE DIALOG */}
            <Dialog 
                open={props.open}
                >
                <DialogContent>
                    <DialogContentText component={'div'} style={{ color: "black" }}>
                        <DeleteText 
                            access={props.access}
                            permission="delete"
                         />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DeleteUpdatePermission
                        handler={()=>{handleDelete(); props.setOpen(false)}}
                        permission="delete"
                        text="DELETE"
                        access={props.access}
                        color="red"
                    />
                    <Button
                        variant="contained"
                        onClick={() => { props.setOpen(false) }}
                        style={{marginLeft:"5px"}}
                    >
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default DeleteSkills;