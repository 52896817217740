import { Button, Dialog, DialogActions, DialogContent, Stack, TextField } from '@mui/material'
import { convertToRaw } from 'draft-js'
import MUIRichTextEditor from 'mui-rte'
import React from 'react'
import { useFormik } from "formik";
import { faq } from '../../../../shared/HTTP/HttpCalls'
import { faqAnsSchema } from '../../../../shared/FormValidationSchema'

const AddAnswer = (props) => {

    const onSubmit = (formData) => {
        const dataToSend = {
            answer: formData.answer
        };
        faq.update(props.rowData.id, dataToSend)
            .then(res => {
                const updateData = props.dialogData.map(data => data.id === res.id ? { dataToSend } : data)
                props.setDialogData(updateData)
                props.fetchFAQ();
                props.snackbarStart("success", `Answer Added`, res.statusText, res.status);
            })
            .catch((err) => {
                props.snackbarStart("error", err.toString());
            });
        props.setAnswerDialog(false);
    };

    const formik = useFormik({
        initialValues: {
            answer: "",
            answerText: ""
        },
        onSubmit,
        validationSchema: faqAnsSchema,
    });

    const handleChange = prop => event => {
        const content = JSON.stringify(convertToRaw(event.getCurrentContent()));
        const plainText = event.getCurrentContent().getPlainText();
        formik.setFieldValue("answer", content);
        formik.setFieldValue("answerText", plainText);
    };

    return (
        <div data-testid="addAnswer-component">
            {/*Dialog */}
            <Dialog
                open={props.open || false}
                onClose={() => props.setAnswerDialog(false)}
            >
                {/*Dialog Content*/}
                <DialogContent>
                    <TextField
                        value={props.rowData?.question}
                        variant="filled"
                        multiline
                        style={{ width: "100%" }}
                        disabled
                    />
                    {/*RTE */}
                    <Stack height={300}>
                        <MUIRichTextEditor
                            style={{ height: "900px" }}
                            controls={[
                                "title",
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                                "undo",
                                "redo",
                                "link",
                                "media",
                                "numberList",
                                "bulletList",
                                "code",
                                "clear",
                                "highlight",
                            ]}
                            onChange={handleChange("answer")}
                            label="Start Typing"
                        />
                    </Stack>
                </DialogContent>
                {/*Dialog Actions*/}
                <DialogActions>
                    <Button
                        onClick={() => { props.setAnswerDialog(false); formik.resetForm(); }}
                        variant="contained">
                        CANCEL
                    </Button>
                    <Button
                        disabled={!(formik.dirty && formik.isValid)}
                        onClick={formik.handleSubmit}
                        variant="contained">
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default AddAnswer;