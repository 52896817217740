import React from "react";
import KnowledgeCards from "./KnowledgeCards";
import KbCreateDialog from "./KbCreateDialog";
import DisplaySnackbar from "../../../../shared/Components/DisplaySnackbar";
import AddPermission from "../../../../shared/Components/AddPermission";
import { getKbPermission } from "../../../../shared/Permission/permissionAccess";
import { snackbarData } from "../../../../shared/Components/DisplaySnackbar";
import { KB } from "../../../../shared/HTTP/HttpCalls";

const KnowledgeBase = (props) => {

    const [data, setData] = React.useState([]);
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);
    const access = getKbPermission;

    React.useEffect(() => {
        KB.findAll()
            .then(res => {
                setData(res.data);
            })
            .catch(err => console.log(err));
    }, []);

    const cardsUpdater = (dataToUpdate) => {
        setData(dataToUpdate);
    };

    return (
        <>
            <KbCreateDialog
                open={createDialogOpen}
                dialogClose={() => { setCreateDialogOpen(false) }}
                setSnackbarOpen={setSnackbarOpen}
                cardsUpdater={cardsUpdater}
                data={data}
            />

            <KnowledgeCards
                data={data}
                setSnackbarOpen={setSnackbarOpen}
                cardsUpdater={cardsUpdater}
            />

            <AddPermission
                handler={() => { setCreateDialogOpen(true) }}
                access={access}
                permission="create"
            />

            <DisplaySnackbar
                open={snackbarOpen.open}
                closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
                message={snackbarOpen.message}
                severity={snackbarOpen.severity}
                statusCode={snackbarOpen.statusCode}
            />
        </>
    );
};
export default KnowledgeBase;