import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import ViewData from "./ViewData";
import { faq } from "../../../../shared/HTTP/HttpCalls";
import AddQuestions from "./AddQuestions";
import AddAnswer from "./AddAnswer";
import {
  getRoleId,
  getUserId,
} from "../../../../shared/LocalStorage/LocalStorageCalls";
import MUIRichTextEditor from "mui-rte";
import DisplaySnackbar from "../../../../shared/Components/DisplaySnackbar";
import { getFAQPermission } from "../../../../shared/Permission/permissionAccess";
import { ExpandMore } from "@mui/icons-material";

const snackbarData = {
  open: false,
  severity: "",
  message: "",
  statusCode: "",
};

const FaqList = () => {
  const [dialogData, setDialogData] = React.useState(); //To display data
  const [openDelete, setOpenDelete] = useState(false); //Dialog to show delete
  const [AccordianSingleData, setAccordianSingleData] = useState({}); //Index of accordian
  const [editDialog, setEditDialog] = useState(false); //Dialog to show Edit
  const [edit, setEditData] = useState({});
  const [viewDialog, setViewDialog] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [answerDialog, setAnswerDialog] = useState(false); //answer Dialog
  const [id, setId] = useState({});
  const [rowData, setRowData] = useState({}); //getRowdata
  const [role, setRole] = useState(""); //getRole
  const [userId, setUserId] = useState(""); //GetUserId
  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);
  const access = getFAQPermission;
  //set snackbar values
  const snackbarStart = (severity, message, st = "", sc = "") => {
    setSnackbarOpen({
      open: true,
      message: message,
      severity: severity,
      statusCode: `${st} ${sc}`,
    });
  };

  //HANDLE DELETE DIALOG
  const handleDelete = (index, data, datarow) => {
    setOpenDelete(true);
    setAccordianSingleData(index);
    setId(data);
    setRowData(datarow);
  };
  //HANDLE EDIT DIALOG
  const handleEdit = (index) => {
    setEditDialog(true);
    setAccordianSingleData(index);
  };
  //EDIT DATA
  const editData = (data) => {
    setEditData(data);
  };
  //ADD ANSWER
  const handleAnswers = (index, data) => {
    setAnswerDialog(true);
    setAccordianSingleData(index);
    setRowData(data);
  };

  //FETCH ALL FAQS
  const fetchFAQ = () => {
    faq
      .findAll()
      .then((res) => {
        setDialogData(res.data);
      })
      .catch((err) => console.log("Error", err));
  };
  //Fetch Role
  const fetchRole = () => {
    setRole(getRoleId());
  };
  //fetch User Id
  const fetchUserId = () => {
    setUserId(getUserId);
  };

  useEffect(() => {
    fetchFAQ();
    fetchRole();
    fetchUserId();
  }, []);

  return (
    <div data-testid="faqList-component">
      {/* SNACK BAR  */}
      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => {
          setSnackbarOpen({ open: false, severity: "", message: "" });
        }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbarData.statusCode}
      />
      {/*FAQ ADD*/}
      <AddAnswer
        open={answerDialog}
        setAnswerDialog={setAnswerDialog}
        AccordianSingleData={AccordianSingleData}
        dialogData={dialogData}
        setDialogData={setDialogData}
        rowData={rowData}
        fetchFAQ={fetchFAQ}
        snackbarStart={snackbarStart}
      />
      {/* FAQ ADD */}
      <AddQuestions
        open={addDialog}
        setAddDialog={setAddDialog}
        setDialogData={setDialogData}
        dialogData={dialogData}
        fetchFAQ={fetchFAQ}
        role={role}
        userId={userId}
        snackbarStart={snackbarStart}
        access={access}
      />
      {/* FAQ EDIT- -QA */}
      <ViewData
        open={viewDialog}
        setViewDialog={setViewDialog}
        AccordianSingleData={AccordianSingleData}
        rowData={rowData}
      />
      {/* FAQ EDIT- -QA */}
      <EditDialog
        open={editDialog}
        setEditDialog={setEditDialog}
        AccordianSingleData={AccordianSingleData}
        edit={edit}
        fetchFAQ={fetchFAQ}
        snackbarStart={snackbarStart}
        access={access}
      />
      {/* FAQ DELETE - -QA */}
      <DeleteDialog
        open={openDelete}
        setOpenDelete={setOpenDelete}
        dialogData={dialogData}
        setDialogData={setDialogData}
        id={id}
        fetchFAQ={fetchFAQ}
        rowData={rowData}
        snackbarStart={snackbarStart}
        access={access}
      />
      {/* CARD AND DATA GRID TO SHOW DATA  */}
      {/* Accordion */}
      {dialogData &&
        dialogData.map((data, index) => (
          <Stack key={index}>
            <Accordion key={index} sx={{ margin: 1 }}>
              {/* Accordion Summary*/}
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                component={"div"}
              >
                <AccordionSummary
                  style={{ zIndex: "999" }}
                  expandIcon={<ExpandMore />}
                >
                  <div>
                    <Container fixed>
                      <Typography
                        component={"span"}
                        style={{ wordWrap: "break-word" }}
                      >
                        {data.question}
                      </Typography>
                    </Container>
                  </div>
                </AccordionSummary>
                <Stack
                  onClick={() => {
                    setViewDialog(true);
                    setRowData(data);
                  }}
                  position={"absolute"}
                  width={"100%"}
                  height={"100%"}
                />
              </Stack>
              {/* Accordion Details*/}
              <AccordionDetails style={{ background: "#eaeff1" }}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                  component={"div"}
                >
                  <div style={{ width: "94%", height: "100%" }}>
                    <MUIRichTextEditor
                      defaultValue={data.answer}
                      controls={[]}
                      readOnly
                    />
                  </div>
                  {/*Role is admin then show add answer button */}
                  {data.answer === "" ? (
                    <Stack>
                      <Button
                        style={{ margin: "10px 0px" }}
                        onClick={() => handleAnswers(index, data)}
                        variant="contained"
                      >
                        Add Answer
                      </Button>
                    </Stack>
                  ) : (
                    <Stack
                      position="relative"
                      direction="row"
                      flexWrap={"wrap"}
                      component={"div"}
                    >
                      {/*Edit and delete button */}

                      <Stack direction="row">
                        <DeleteIcon
                          onClick={() => handleDelete(index, data.id, data)}
                          style={{
                            cursor: "pointer",
                            color: "grey",
                            margin: "10px 10px",
                          }}
                        />
                        <EditIcon
                          onClick={() => {
                            handleEdit(index);
                            editData(data);
                          }}
                          style={{
                            cursor: "pointer",
                            color: "grey",
                            margin: "10px 0px",
                          }}
                        />
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        ))}
    </div>
  );
};
export default FaqList;
