import React from "react";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { kbSchema } from "../../../../shared/FormValidationSchema";
import { Upload, KB, Media } from "../../../../shared/HTTP/HttpCalls";
import { snackbarStart } from "../../../../shared/Components/DisplaySnackbar";

const UpdateDialog = (props) => {

    const [file, setFile] = React.useState([]);
    const [oldFile, setOldFile] = React.useState(true);

    const onSubmit = (formData) => {
        //no new files added
        if (oldFile && file.length === 0) {
            const updateKB = {
                name: formData.name,
                description: formData.description,
                version: formData.version,
                filesrc: props.dialogData.filesrc
            };
            KB.update(props.dialogData._id, updateKB)
                .then((res) => {
                    props.cardsUpdater(props.data.map(obj => obj._id === res.data._id ? res.data : obj));
                    props.setSnackbarOpen(snackbarStart("success", "Record Updated", res.statusText, res.status));
                })
        }
        //new files added
        else {
            Media.delete(props.dialogData.filesrc)
                .then(() => {
                    Upload.create(file)
                        .then(resp => {
                            const updateKB = {
                                name: formData.name,
                                description: formData.description,
                                version: formData.version,
                                filesrc: resp.data[0]._id
                            };
                            KB.update(props.dialogData._id, updateKB)
                                .then((response) => {
                                    props.cardsUpdater(props.data.map(obj => obj._id === response.data._id ? response.data : obj));
                                    props.setSnackbarOpen(snackbarStart("success", "Card updated", response.statusText, response.status));
                                })
                        })
                })
        }
        handleClose();
    };

    const formik = useFormik({
        initialValues: {
            name: props.dialogData.name || "",
            description: props.dialogData.description || "",
            version: props.dialogData.version || ""
        },
        onSubmit,
        validationSchema: kbSchema,
        enableReinitialize: true
    });

    const handleFiles = (event) => {
        let fData = new FormData();
        fData.append(event.target.name, event.target.files[0]);
        setFile(fData);
        setOldFile(false);
    };

    const handleClose = () => {
        props.dialogClose();
        formik.resetForm();
        setFile([]);
        setOldFile(true);
    };

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                Update {props.dialogData.name}
                <div id="icons" style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", justifyContent: "space-between" }}>
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ margin: "0px 5px", cursor: "pointer" }}
                    />
                </div>
            </DialogTitle>

            <DialogContent>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 0 }}
                    sx={{ mb: 2 }}
                >
                    <TextField
                        {...formik.getFieldProps("name")}
                        {... (formik.touched.name && formik.errors.name && { error: true })}
                        helperText={(formik.touched.name && formik.errors.name) || " "}
                        label="Name"
                        variant="filled"
                        sx={{ width: "100%", padding: "5px" }}
                    >
                    </TextField>
                    <TextField
                        {...formik.getFieldProps("version")}
                        {... (formik.touched.version && formik.errors.version && { error: true })}
                        helperText={(formik.touched.version && formik.errors.version) || " "}
                        label="Version"
                        variant="filled"
                        sx={{ width: "100%", padding: "5px" }}
                    >
                    </TextField>
                </Stack>
                <TextField
                    {...formik.getFieldProps("description")}
                    {...(formik.touched.description && formik.errors.description && { error: true })}
                    helperText={(formik.touched.description && formik.errors.description) || " "}
                    label="Description"
                    minRows={3}
                    multiline
                    variant="filled"
                    placeholder="Enter Description"
                    sx={{ width: "100%", padding: "5px" }}
                />
            </DialogContent>

            <DialogActions>
                <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", justifyContent: "space-between" }}>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ margin: "0px 2px" }}
                    >
                        {file.length !== 0 ? "ATTACHED" : "OVERWRITE FILE"}
                        <input
                            id="files" name="files"
                            type="file"
                            onChange={handleFiles}
                            hidden
                        />
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ margin: "0px 2px" }}
                        onClick={formik.handleSubmit}
                        disabled={!(formik.isValid && formik.dirty) && oldFile}
                    >
                        UPDATE
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
export default UpdateDialog;