import React from 'react'

const DeleteText = (props) => {
    return (
        <div data-testid="deleteText-component">
            {
                props.access && props.access.filter(data => {
                    return data.type === props.permission
                }).map((data, index) => (
                    data.type && data.enabled ?
                        <div key={index}>
                            Are you sure you want to delete this record?
                        </div> :
                        <div key={index}>
                            You are not allowed to delete this record.
                        </div>
                ))
            }
        </div>

    )
}

export default DeleteText