import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const Actions = (props) => {
    return (
        <div data-testid="actionComponent">
            <DeleteIcon
                style={{ 
                    margin: "0 5px", 
                    color: "black", 
                    cursor: "pointer" 
                }}
                onClick={() => { props.setDeleteDialog(true) }}
            />
            <EditIcon
                style={{ 
                    margin: "0 5px", 
                    color: "black", 
                    cursor: "pointer" 
                }}
                onClick={() => { 
                        props.setEdit(true); 
                        props.editHandler();
                    }}
            />
            <CloseIcon
                style={{ 
                    margin: "0 5px", 
                    color: "black", 
                    cursor: "pointer", 
                }}
                onClick={() => { 
                        props.setOpen(false); 
                        props.setEdit(false) 
                    }}
            />
        </div>
    );
};
export default Actions;