import React from "react";
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { KB, Media } from "../../../../shared/HTTP/HttpCalls";
import { snackbarStart } from "../../../../shared/Components/DisplaySnackbar";

const DeleteDialog = (props) => {

    const deleteHandler = () => {
        Media.delete(props.dialogData.filesrc)
        .then(() => {
            KB.delete(props.dialogData._id)
            .then((res) => {
                props.cardsUpdater(props.data.filter(obj => obj._id !== res.data._id));
                props.setSnackbarOpen(snackbarStart("success", "Card Deleted", res.statusText, res.status));
            })
        })
        .catch(err => {
            props.setSnackbarOpen(snackbarStart("error", err.toString()));
        });
        props.dialogClose();
    };

    return (
        <Dialog open={props.open} onClose={props.dialogClose} fullWidth maxWidth="xs">
            <DialogTitle>
                DELETE
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    Are you sure you want to delete {props.dialogData.name} card ?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={props.dialogClose}
                >
                    CANCEL
                </Button>
                <Button
                    style={{ background: "red" }}
                    variant="contained"
                    onClick={deleteHandler}
                >
                    DELETE
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default DeleteDialog;