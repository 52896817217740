import React from "react";

import LeaveTable from "./LeaveTable";
import LeaveCard from "./LeaveCard";

import { leaves } from "../../../../shared/HTTP/HttpCalls";
import { getUserId } from "../../../../shared/LocalStorage/LocalStorageCalls";
import { UserPermissions } from "../../../../shared/global-constants";

const Leave = () => {
  const [tableData, setTableData] = React.useState([]);
  const [remainingLeaves, setRemainingLeaves] = React.useState({});

  React.useEffect(() => {
    const fetchData = async () => {
      UserPermissions.leave.find
        ? leaves
            .findAll()
            .then((res) => {
              res.data.sort((a) => (a.status === "pending" ? -1 : 1));
              setTableData(res.data);
            })
            .catch((err) => console.log("error: ", err))
        : leaves
            .getList(getUserId())
            .then((res) => {
              res.data.sort((a) => (a.status === "pending" ? -1 : 1));
              setTableData(res.data);
            })
            .catch((err) => console.log(err));

      if (UserPermissions.leave.remainingleaves) {
        leaves
          .remainingLeaves(getUserId())
          .then((res) => {
            setRemainingLeaves(res.data);
          })
          .catch((err) => console.log(err));
      }
    };
    fetchData();
  }, []);

  const tableUpdater = (updatedTableData) => {
    setTableData(updatedTableData);
  };

  return (
    <div>
      {Object.keys(remainingLeaves).length !== 0 && (
        <LeaveCard remainingLeaves={remainingLeaves} />
      )}
      <LeaveTable
        rows={tableData}
        tableUpdater={tableUpdater}
        remainingLeaves={remainingLeaves}
      />
    </div>
  );
};

export default Leave;
