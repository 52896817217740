import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { project } from "../../../../shared/HTTP/HttpCalls";
import { useFormik } from "formik";
import { ProjectSchema } from "../../../../shared/FormValidationSchema";

const AddProjects = (props) => {
  const [open, setOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState("");

  //HandleClose for closing dialog
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  //ADDING DATA
  const onSubmit = (formData) => {
    let technoFormat = [];
    for (let i = 0; i < formik.values.technologies.length; ++i) {
      technoFormat = [
        ...technoFormat,
        { technologies: formik.values.technologies[i] },
      ];
    }
    const request = {
      name: formData.name,
      description: formData.description,
      technologies: technoFormat,
      client: {
        _id: clientDetails.id,
      },
      startdate: formData.startDate,
      enddate: formData.endDate,
    };
    project
      .create(request)
      .then((res) => {
        props.fetchData();
        props.snackbarStart(
          "success",
          `Record Added`,
          res.statusText,
          res.status
        );
      })
      .catch((err) => {
        props.snackbarStart("error", err.toString());
      });
    formik.resetForm();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      startDate: "",
      endDate: "",
      client: "",
      clientData: props.clientData,
      technologies: [],
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: ProjectSchema,
  });

  return (
    <div data-testid="addproject-component">
      {/* DIALOG */}
      <Dialog open={open} onClose={handleClose}>
        {/* DIALOG CONTENT */}
        <DialogContent>
          <Stack spacing={2}>
            <Typography>Add Project</Typography>
            {/* NAME & DESCRIPTION */}
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1 }}>
              <TextField
                {...formik.getFieldProps("name")}
                {...(formik.errors.name &&
                  formik.touched.name && { error: true })}
                helperText={(formik.touched.name && formik.errors.name) || " "}
                label="Name"
                variant="filled"
              />
              <TextField
                {...formik.getFieldProps("description")}
                {...(formik.errors.description &&
                  formik.touched.description && { error: true })}
                helperText={
                  (formik.touched.description && formik.errors.description) ||
                  " "
                }
                label="Description"
                variant="filled"
              />
            </Stack>
            {/* TECHNOLOGY */}
            <Stack>
              {
                <Autocomplete
                  multiple
                  {...formik.getFieldProps("technologies")}
                  freeSolo={true}
                  getOptionLabel={(option) => option}
                  options={[]}
                  onChange={(_, val) => {
                    formik.setFieldValue("technologies", val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...(formik.errors.technologies &&
                        formik.touched.technologies && { error: true })}
                      helperText={
                        (formik.touched.technologies &&
                          formik.errors.technologies) ||
                        "Press 'Enter' to seperate"
                      }
                      label="Technologies"
                      placeholder="Technologies"
                    />
                  )}
                />
              }
            </Stack>
            {/* START AND END DATE */}
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1 }}>
              <TextField
                {...formik.getFieldProps("startDate")}
                {...(formik.errors.startDate &&
                  formik.touched.startDate && { error: true })}
                helperText={
                  (formik.touched.startDate && formik.errors.startDate) || " "
                }
                type="date"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                variant="filled"
                sx={{ width: "100%" }}
              />
              <TextField
                {...formik.getFieldProps("endDate")}
                {...(formik.errors.endDate &&
                  formik.touched.endDate && { error: true })}
                helperText={
                  (formik.touched.endDate && formik.errors.endDate) || " "
                }
                type="date"
                label="End Date"
                InputLabelProps={{ shrink: true }}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </Stack>
            {/*SELECT CLIENT NAME */}
            <Stack>
              <TextField
                {...formik.getFieldProps("client")}
                {...(formik.errors.client &&
                  formik.touched.client && { error: true })}
                helperText={
                  (formik.touched.client && formik.errors.client) || " "
                }
                label="Client Info"
                select
                variant="filled"
                required
              >
                {formik.values.clientData &&
                  formik.values.clientData.map((data) => (
                    <MenuItem
                      onClick={() => {
                        setClientDetails(data);
                      }}
                      key={data._id}
                      value={data.name}
                    >
                      {data.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
          </Stack>
        </DialogContent>
        {/* DIALOG ACTIONS BUTTON */}
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!(formik.dirty && formik.isValid)}
            onClick={formik.handleSubmit}
            variant="contained"
            autoFocus
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* FLOATING BUTTON */}
      <div
        onClick={() => setOpen(true)}
        style={{ position: "fixed", bottom: "1%", right: "1%" }}
      >
        <Fab color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
};
export default AddProjects;
